import * as React from 'react';
import { styled } from '@mui/system';
import { TextField , Select} from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


export const StyledTextField = styled(TextField, {
    name: "StyledTextField",
  })({
    width: "100%",
    
    "& .MuiInputBase-root": {},
  });
  
export const StyledSelect = styled(Select, {
    name: "StyledSelect",
  })({
    width: "100%",
    marginRight:"1rem",
    "& .MuiInputBase-root": {},
  });
  
export const StyledDatePicker = styled(DatePicker, {
    name: "StyledDatePicker",
  })({
    width: "100%",
  
    "& .MuiInputBase-root": {},
  });