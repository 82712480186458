import React from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

export default function PowerBiChart() {
    return (
        <div>
            {/* <iframe title="Time Series Decomposition" width="100%" height="1000" src="
https://app.powerbi.com/reportEmbed?reportId=cf7854a0-c7d6-4b74-b98a-1fcf9c80f719&autoAuth=true&ctid=5f9bacc0-ffe8-41f7-8d25-215d55cb0f96"
                frameborder="0" allowFullScreen="true"></iframe> */}
            <iframe title="Time Series Decomposition" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=f107d0a4-5524-4f16-8d3f-04f9cfdc444e&autoAuth=true&ctid=5f9bacc0-ffe8-41f7-8d25-215d55cb0f96" frameborder="0" allowFullScreen="true"></iframe>
            <iframe title="Business Insight" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=339cdbdd-167b-44cf-b947-2d888f63520d&autoAuth=true&ctid=5f9bacc0-ffe8-41f7-8d25-215d55cb0f96" frameborder="0" allowFullScreen="true"></iframe>
            <iframe title="Evaluation Metrics" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=dfeea031-3b11-40da-a215-f89c3ef9173e&autoAuth=true&ctid=5f9bacc0-ffe8-41f7-8d25-215d55cb0f96" frameborder="0" allowFullScreen="true"></iframe>
        </div>
    )
}