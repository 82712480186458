import React, { useEffect, useState } from "react";
import { StyledSelect } from "../../components/inputFields/FormFields";
import { Box, Divider, FormControl, InputLabel, MenuItem, Paper, Typography, Select } from "@mui/material";
import { QuicksightChart } from "../../components/QuicksightDashboard/QuicksightChart";
import { useNavigate, useParams } from "react-router-dom";
const dashboardLinks = [
  {
    id: "TimeSeriesDecomposition",
    label: "Time Series Decomposition",
    to: "https://app.powerbi.com/view?r=eyJrIjoiNzljOThlZjItOGQ2Yi00OGQxLThlNTUtYTFkZDlhMTgyM2EzIiwidCI6IjVmOWJhY2MwLWZmZTgtNDFmNy04ZDI1LTIxNWQ1NWNiMGY5NiJ9",
  },
  {
    id: "Business Insights",
    label: "Business Insights",
    to: "https://app.powerbi.com/view?r=eyJrIjoiNmRmNjRiNmYtZDhjOC00ZWU2LWI4YjUtZjlmMTI5OTkyZjZiIiwidCI6IjVmOWJhY2MwLWZmZTgtNDFmNy04ZDI1LTIxNWQ1NWNiMGY5NiJ9",
  },
  {
    id: "Evaluation Metrics",
    label: "Evaluation Metrics",
    to: "https://app.powerbi.com/view?r=eyJrIjoiYWYxZmU5NTMtZTJhZS00Yzc4LTk0OTEtMjlkMmNiYzg5YjExIiwidCI6IjVmOWJhY2MwLWZmZTgtNDFmNy04ZDI1LTIxNWQ1NWNiMGY5NiJ9",
  },
  {
    id: "CustomerInsights",
    label: "Customer Insights",
    to: "https://app.powerbi.com/view?r=eyJrIjoiZGViMjUzY2EtY2M5Ni00ODM0LWE3ZGItMTU0MWE5OTIzYTVlIiwidCI6IjVmOWJhY2MwLWZmZTgtNDFmNy04ZDI1LTIxNWQ1NWNiMGY5NiJ9",
  },
  {
    id: "InventoryInsights",
    label: "Inventory Insights",
    to: "https://app.powerbi.com/view?r=eyJrIjoiMjJkNTI5ZjktZDVjYy00ZDlhLTg5ZTYtMDk2ZDVlNzA2MTRmIiwidCI6IjVmOWJhY2MwLWZmZTgtNDFmNy04ZDI1LTIxNWQ1NWNiMGY5NiJ9",
  }
];

const LIstDashboards = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [link, setLink] = useState(dashboardLinks.length > 0 ? dashboardLinks[2].to : '');
  useEffect(() => {
    const dashboard = dashboardLinks.find(d => d.id === id);
    if (dashboard) {
      setLink(dashboard.to);
    }
  }, [id]);

  const handleChange = (event) => {
    const selectedDashboard = dashboardLinks.find(d => d.to === event.target.value);
    if (selectedDashboard) {
      navigate(`/app/visualizations/dashboard/${selectedDashboard.id}`);
      setLink(selectedDashboard.to);
    }
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, p: 2 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",

              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">Dashboard</Typography>
            <div>
              <FormControl fullWidth>
              <InputLabel id="dashboard-select-label">Select Dashboard</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={link}
                  label="Select Dashboard"
                  onChange={handleChange}
                >{dashboardLinks.map((dashboard) => (
                  <MenuItem key={dashboard.id} value={dashboard.to}>
                    {dashboard.label}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              <Divider />
            </div>
          </div>
          <QuicksightChart chartLink={link} />
        </Paper>
      </Box>
    </>
  );
};

export default LIstDashboards;
