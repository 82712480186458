import React from "react";
import { Paper, Grid, Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import MuiIcon from "./MuiIcon";



const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


export default function DashboardCards({ cardType, cardStyle, type, cardData }) {
    return (
        <>
            {
                cardType && cardType == "alert" ?
                    <Paper style={{ height: cardStyle?.height ? cardStyle.height : "100%", width: cardStyle?.width ? cardStyle.width : "100%", background: cardStyle?.background ? cardStyle.background : "#FFFFFF", border: cardStyle?.cardBorderStyle ? cardStyle.cardBorderStyle : "none", textAlign: "center", display: "flex", justifyContent: "center" }}>
                        <Grid container xs={12} sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <Grid xs={2}>
                                <Item style={{ color: cardStyle?.iconColor ? cardStyle.iconColor : "#666666" }}> <MuiIcon iconName={cardData?.icon} iconSize={cardStyle?.iconSize} /></Item>
                            </Grid>
                            <Grid xs={8}>
                                <Item style={{ color: cardStyle?.titleColor ? cardStyle.titleColor : "#666666", fontWeight: "bold", fontSize: ".8em", display: "flex", justifyContent: "center", width: "100%", height: "100%", textAlign: "center", paddingTop: "10%" }}>{cardData?.title}</Item>
                            </Grid>
                            <Grid xs={2}>
                                <Item style={{ color: cardStyle?.percentageColor ? cardStyle.percentageColor : "#666666", fontWeight: "bold", fontSize: "1.5em" }}>{cardData?.percentage1}</Item>
                            </Grid>
                        </Grid>
                    </Paper>
                    :
                    <Paper style={{ minHeight:"120px",height: cardStyle?.height ? cardStyle.height : "100%", width: cardStyle?.width ? cardStyle.width : "100%", background: cardStyle?.background ? cardStyle.background : "#FFFFFF", padding: "2%", border: cardStyle?.cardBorderStyle ? cardStyle.cardBorderStyle : "none" }}>
                        <div style={{ color: cardStyle?.titleColor ? cardStyle?.titleColor : "#666666", textAlign: "center", fontSize: ".8em", fontWeight: "bold" }}>
                            {cardData.title}
                        </div>
                        {
                            type === "type1" ? (
                                <Grid container>
                                    <Grid container xs={5} style={{ display: "flex", justifyContent: "center", alignItems: "center", background: cardStyle?.background ? cardStyle.background : "#FFFFFF" }}>
                                        <Grid item xs={12}>
                                            <div style={{ margin: "auto", fontWeight: "bold", fontSize: "1.5em", color: cardStyle?.percentage1Color ? cardStyle?.percentage1Color : "#666666", background: cardStyle?.background ? cardStyle.background : "#FFFFFF", width: "100%", display: "flex", justifyContent: "center" }}>{cardData.percentage1}</div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{ margin: "auto", fontWeight: "bold", fontSize: ".8em", color: cardStyle?.percentage1Color ? cardStyle?.percentage1Color : "#666666", background: cardStyle?.background ? cardStyle.background : "#FFFFFF", width: "100%", display: "flex", justifyContent: "center", textAlign: "center", paddingBottom: "15%", paddingRight: "5%" }}>{cardData.percentage1Title}</div>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={7}>
                                        <Grid item xs={4}>
                                            <Item style={{ display: "flex", fontSize: "1em", fontWeight: "bold", textAlign: "center", color: cardStyle.percentage2Color ? cardStyle.percentage2Color : "#666666", background: cardStyle?.background ? cardStyle.background : "#FFFFFF", justifyContent: "flex-end" }}>{cardData.percentage2}</Item>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Item style={{ color: cardStyle?.percentage2TitleColor ? cardStyle?.percentage2TitleColor : "#666666", textAlign: "center", fontSize: ".8em", background: cardStyle?.background ? cardStyle.background : "#FFFFFF" }}>{cardData.percentage2Title}</Item>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Item style={{ display: "flex", color: cardStyle?.percentage3Color ? cardStyle?.percentage3Color : "#666666", fontSize: "1em", fontWeight: "bold", alignItems: "center", textAlign: "center", background: cardStyle?.background ? cardStyle.background : "#FFFFFF" }}>{cardData.percentage3}</Item>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Item style={{ color: cardStyle?.percentage3TitleColor ? cardStyle?.percentage3TitleColor : "#666666", textAlign: "center", fontSize: ".8em", background: cardStyle?.background ? cardStyle.background : "#FFFFFF" }}>{cardData.percentage3Title}</Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                                :
                                type === "type2" && (
                                    <>
                                        <Item style={{ margin: "auto", fontWeight: "bold", fontSize: "1.1em", color: cardStyle?.percentage1Color ? cardStyle?.percentage1Color : "#666666", background: cardStyle?.background ? cardStyle.background : "#FFFFFF" }}>
                                            {cardData.percentage1}
                                        </Item>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <div style={{ color: cardStyle?.percentage2Color ? cardStyle?.percentage2Color : "#666666", fontSize: "1em", fontWeight: "bold", textAlign: "center" }}>{cardData.percentage2}</div>
                                                <div style={{ color: cardStyle?.percentage2TitleColor ? cardStyle?.percentage2TitleColor : "#666666", textAlign: "center", fontSize: ".8em" }}>{cardData.percentage2Title}</div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div style={{ color: cardStyle?.percentage3Color ? cardStyle?.percentage3Color : "#666666", fontSize: "1em", fontWeight: "bold", textAlign: "center" }}>{cardData.percentage3}</div>
                                                <div style={{ color: cardStyle?.percentage3TitleColor ? cardStyle?.percentage3TitleColor : "#666666", textAlign: "center", fontSize: ".8em" }}>{cardData.percentage3Title}</div>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                        }
                        {
                            !type &&
                            <>
                                {cardData ? cardData : null}
                            </>
                        }

                    </Paper>

            }
        </>
    )
}