// reducer.js
const initialState = {
    progress: 0,
    error: null,
    location: null,
  };
  
 export const ModalUploadReducer = (state = initialState, action) => {
    switch (action.type) {
      case "UPLOAD_PROGRESS":
        return {
          ...state,
          progress: action.payload,
          error: null,
        };
      case "UPLOAD_ERROR":
        return {
          ...state,
          progress: 0,
          error: action.payload,
        };
      case "UPLOAD_SUCCESS":
        return {
          ...state,
          progress: 100,
          error: null,
          location: action.payload,
        };
      default:
        return state;
    }
  };
  

  