import { Box, Container, Paper } from "@mui/material";
import { QuicksightChart } from "../../../components/QuicksightDashboard/QuicksightChart";

export const SelectedModalDashboard = ({link}) => {
  return (
    <>
      <Container component={Box} p={0}>
        <Paper component={Box} p={0}>
          <QuicksightChart
            chartLink={link}
          />
        </Paper>
      </Container>
    </>
  );
};
