import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  EnhancedTableToolbar,
  Header,
  getComparator,
  stableSort,
} from "./Header";
import { Collapse, IconButton, Radio } from "@mui/material";

TableWrapper.propTypes = {
  tableData: PropTypes.array.isRequired,
  headerData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  selectionType: PropTypes.string,
  toolKit: PropTypes.any,
  pagination: PropTypes.bool,
};

export default function TableWrapper({
  tableData,
  headerData,
  onRowClick,
  onCellClick,
  selectionType,
  toolKit,
  pagination,
  accordindata,
}) {
  console.log(accordindata,"77777777777777777777");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openRows, setOpenRows] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableData.map((n) => n["data0"]); // Fix the key name here
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, name) => {
    let newSelected = [];

    if (selectionType === "checkbox") {
      const selectedIndex = selected.indexOf(name);

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else {
        newSelected = selected.filter((item) => item !== name);
      }
    } else if (selectionType === "radio") {
      if (selected !== name) {
        newSelected = name;
      }
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => {
    if (selectionType === "checkbox") {
      return selected.indexOf(name) !== -1;
    } else if (selectionType === "radio") {
      return selected === name;
    }

    return false;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty tableData.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(tableData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );
  console.log(tableData, headerData, "rrrrrrrrrrrrrrrrr");

  const handleRowToggle = (sample_key) => {
    const openIndex = openRows.indexOf(sample_key);
    let newOpenRows = [];

    if (openIndex === -1) {
      newOpenRows = [...openRows, sample_key];
    } else if (openIndex === 0) {
      newOpenRows = openRows.slice(1);
    } else if (openIndex === openRows.length - 1) {
      newOpenRows = openRows.slice(0, -1);
    } else if (openIndex > 0) {
      newOpenRows = [
        ...openRows.slice(0, openIndex),
        ...openRows.slice(openIndex + 1),
      ];
    }

    setOpenRows(newOpenRows);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {toolKit && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            toolKit={toolKit}
          />
        )}

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <Header
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              headCells={headerData}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              selectionType={selectionType}
            />
            <TableBody>
              {visibleRows.map((row, rowIndex) => {
                const isItemSelected = isSelected(row["data0"]); // Fix the index here
                const labelId = `enhanced-table-checkbox-${rowIndex}`;
                const isOpen = openRows.includes(row.sample_key);
                return (
                  <>
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row["data0"]} // Fix the index here
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        onRowClick
                          ? onRowClick(row, rowIndex)
                          : console.log(row, rowIndex);
                      }}
                    >
                      <TableCell padding="checkbox">
                        {selectionType === "checkbox" ? (
                          <Checkbox
                            onClick={(event) =>
                              handleClick(event, row["data0"])
                            } // Fix the index here
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        ) : selectionType === "radio" ? (
                          <Radio
                            name="rowSelection"
                            checked={isItemSelected}
                            onClick={(event) =>
                              handleClick(event, row["data0"])
                            }
                            color="primary"
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        ) : null}
                      </TableCell>
                      {Object.keys(row).map((test, index) => {
                        return (
                          <React.Fragment key={index}>
                            {index === 0 ? (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                onClick={() => {
                                  onCellClick
                                    ? onCellClick(row[test], index)
                                    : console.log(test, index);
                                }}
                              >
                                {row["data0"]} {/* Fix the index here */}
                              </TableCell>
                            ) : (
                              <TableCell align="left">
                                {row["data" + index] === "success" ? (
                                  <CheckCircleIcon color="success" />
                                ) : (
                                  row["data" + index]
                                )}
                              </TableCell>
                            )}
                          </React.Fragment>
                        );
                      })}
                      {selectionType === "accordin" ? (
                        <>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleRowToggle(row.sample_key)}
                            >
                              {isOpen ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        </>
                      ) : null}
                    </TableRow>
                    {selectionType === "accordin" ? (
                      <>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}
                          >
                            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                              <Box
                                sx={{ margin: 1 }}
                                style={{
                                  background: "#f2f2f2",
                                  padding: "20px",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div"
                                  style={{ marginBottom: "2rem" }}
                                ></Typography>
                                <DataList data={accordindata[rowIndex]} />
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : null}
                  </>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}

const DataList = ({ data }) => {
  return (
    <div>
      <p>
        <strong>ARIMA Order:</strong> {data.ARIMA_Order}
      </p>
      <p>
        <strong>n_obs:</strong> {data.n_obs}
      </p>
      <p>
        <strong>Definition:</strong> {data.definition.state},{" "}
        {data.definition.chain}, {data.definition.product}
      </p>
      <p>
        <strong>SQL Query:</strong> {data.sql}
      </p>
      <p>
        <strong>Forecast Period:</strong> {data.forecast_period}
      </p>
      <p>
        <strong>Non Zero:</strong> {data.non_zero}
      </p>
      <p>
        <strong>Sample Period:</strong> {data.sample_period}
      </p>
      <div style={{ display: "flex", flexDirection: "row",justifyContent:"space-evenly" }}>
        
        <div>
          <p>
            <strong>Test Metrics:</strong>
          </p>
          <ul>
            {Object.entries(data.test_metrics).map(([metric, value]) => (
              <li key={metric}>
                <strong>{metric}:</strong>{" "}
                {typeof value === "number" ? value : JSON.stringify(value)}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <p>
            <strong>Train Metrics:</strong>
          </p>
          <ul>
            {Object.entries(data.train_metrics).map(([metric, value]) => (
              <li key={metric}>
                <strong>{metric}:</strong>{" "}
                {typeof value === "number" ? value : JSON.stringify(value)}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <p>
        <strong>Forecast:</strong> {data.forecast.join(", ")}
      </p>
    </div>
  );
};
