import React from 'react'
import { Box, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { listWorkspaceStyles } from '../dashboard/SampleModal/ModalListStyles';

const ScenarioAnalyzerLayoutWithApi = () => {
    const classes = listWorkspaceStyles();

  return (
    <div className={classes.layout}>
      <Typography className={classes.title}>Scenario Analyzer With Api</Typography>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Outlet />
      </Box>
    </div>
  )
}

export default ScenarioAnalyzerLayoutWithApi;