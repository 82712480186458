
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { LoadingBar } from "../Loaders/CircularLoader";

const useStyles = makeStyles((theme) => ({
    button1: {
      marginRight: theme.spacing(38),
      marginLeft: theme.spacing(5),
    },
    button2: {
      marginLeft: theme.spacing(90),
    },
    container: {
      width: "100%",
      minHeight: "100vh",
      height:"100%",
      overflow: "auto",
    },
    iframeWrapper: {
      height: "100%",
      display: "flex",
     
    },
    iframe: {
      border: "none",
      width: "100%",
      minHeight:"100vh",
      height:"100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  }));
export function QuicksightChart({chartLink}) {
    const classes = useStyles();
    const [link,setLink]=useState(chartLink)
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const iframe = document.getElementById('myIframe');
  
      const handleLoadStart = () => {
        setIsLoading(true);
      };
  
      const handleLoadEnd = () => {
        setIsLoading(false);
      };
  
      // Add event listeners when the component mounts
      iframe.addEventListener('loadstart', handleLoadStart);
      iframe.addEventListener('load', handleLoadEnd);
      iframe.addEventListener('error', handleLoadEnd);
  
      // Remove event listeners when the component unmounts
      return () => {
        iframe.removeEventListener('loadstart', handleLoadStart);
        iframe.removeEventListener('load', handleLoadEnd);
        iframe.removeEventListener('error', handleLoadEnd);
      };
    }, []);

   useEffect(() => {
    if(chartLink)
     setLink(chartLink)
   }, [chartLink])
   
  return (
    <div className={classes.container}>
     {isLoading && (
        <div>
          <LoadingBar/>
        </div>
      )}
    <div className={classes.iframeWrapper}>
      <iframe id="myIframe" className={classes.iframe} src={link} />
    </div>
  </div>
  );
}
