import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin);

// Set your target value here




const MyBarChart = ({ dataset, targetValue, targetValue2 }) => {
    const options = {
        scales: {
            x: {
                stacked: false,
                categoryPercentage: 0.8,
                barPercentage: 0.9,
            },
            y: {
                stacked: false,
                beginAtZero: true,
                ticks: {
                    suggestedMin: 0,
                    suggestedMax: 100,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: targetValue,
                        yMax: targetValue,
                        borderColor: 'rgb(255, 99, 132)',
                        borderWidth: 2,
                        borderDash: [10, 5],
                        label: {
                            content: 'Target',
                            enabled: true,
                            position: 'start',
                        },
                    }
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                color: 'black',
                font: {
                    weight: 'bold',
                },
                formatter: (value) => {
                    return value+"%";
                },
            },
        },
    };

    if (targetValue2) {
        options.plugins.annotation.annotations['line2'] = {
            type: 'line',
            yMin: targetValue2,
            yMax: targetValue2,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            borderDash: [10, 5],
            label: {
                content: 'Target',
                enabled: true,
                position: 'start',
            },
        }
    }

    return (
        <div>
            <Bar data={dataset} options={options} />
        </div>
    );
};

export default MyBarChart;
