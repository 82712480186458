// reducers.js

const initialState = {
    sampleData: [],
  };
  
  export const SampleDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SAVE_SAMPLE_DATA':
        return {
          ...state,
          sampleData: action.payload,
        };
      case 'UPDATE_SAMPLE_DATA':
        return {
          ...state,
          sampleData: state.sampleData.map((dataItem) =>
            dataItem.id === action.payload.id ? action.payload : dataItem
          ),
        };
      case 'DELETE_SAMPLE_DATA':
        return {
          ...state,
          sampleData: state.sampleData.filter(
            (dataItem) => dataItem.id !== action.payload
          ),
        };
      default:
        return state;
    }
  };
  