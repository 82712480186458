const initialState = {
  TemporalData: {
    Start: "",
    End: "",
    Split: "",
    Horizon: null,
    Period: null,
    TableName: null,
    ConfigPath: null,
    ForcastWindow: null,
    DecisionWindow: null,
    CustomerLev: null,
    ProductLev: null,
    GeoLev: null,
    Measure: null,
    Distinct: null,
  },
  ModalSuite: {
    trainArima: true,
    trainEts: true,
    train3ma: true,
    trainCnnqr: true,
    trainDeeparplus: true,
    trainProphet: true,
  },
  ModalSuiteDetails: {
    modelSuiteName: "",
  },
  Attributes: {
    query: "",
  },
};

export const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_TEMPORAL_DATA":
      return {
        ...state,
        TemporalData: { ...state.TemporalData, ...action.payload },
      };
    case "TOGGLE_MODAL_SUITE_TRAINING":
      return {
        ...state,
        ModalSuite: {
          ...state.ModalSuite,
          [action.payload.model]: action.payload.value,
        },
      };
    case "UPDATE_MODEL_SUITE_NAME":
      return {
        ...state,
        ModalSuiteDetails: { ...state.ModalSuiteDetails, ...action.payload },
      };
    case "UPDATE_ATTRIBUTES_DATA":
      return {
        ...state,
        Attributes: { ...state.Attributes, query: action.payload },
      };
    case "UPDATE_STATE":
      return { ...state, ...action.payload };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
