import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { listWorkspaceStyles } from "../dashboard/SampleModal/ModalListStyles";
import Sidebar from "./LIstDashboards";
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRECT_ACCESS_KEY,
});


const VisualizationsLayout = (props) => {
  const classes = listWorkspaceStyles();
  const params = useParams();
  console.log(params.id, "===========")

  const enableExport = () => {
    const allowedPages = ["ForecastInsightDashboard"];
    console.log(allowedPages.includes(params.id),"++++++++++")
    return allowedPages.includes(params.id)
  }

  const handleDownload = () => {
    const s3 = new AWS.S3();

    const params = {
      Bucket: 'sagemaker-project-p-hozdhwgfu937',
      Key: `forecasted_outputs/iowa/forecast_file_new.csv`,
    };


    function downloadBlob(blob, name = `forecast.csv`) {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement('a');
      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;
      // Append link to the body
      document.body.appendChild(link);
      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    }

    s3.getObject(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        let csvBlob = new Blob([data.Body.toString()], {
          type: 'text/csv;charset=utf-8;',
        });
        console.log("inside get s3")
        downloadBlob(csvBlob, `forecast.csv`);
      }
    });



}

  return (
    <div className={classes.layout}>
      <div className={classes.titleContainer}>
        <span className={classes.title}>Visualizations</span>
        {enableExport() &&
          <span className={classes.handleDownload}>
            <Button
              variant="contained"
              className={classes.exportButton}
              sx={{ mr: 1 }}
              onClick={handleDownload}
            >
              {" "}
              Export
            </Button>
          </span>
        }
      </div>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Outlet />
      </Box>
    </div>
  );
};

export default VisualizationsLayout