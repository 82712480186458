export const replaceMultipleStringValues = (replacements, originalString) => {
    
    let regex = new RegExp(Object.keys(replacements).join("|"), "gi");
    console.log(regex,"===========")

    let modifiedString = originalString.replace(regex, function (matched) {
        console.log(replacements,matched,"--------")
        return replacements[matched];
    });

    console.log(modifiedString);
    return modifiedString;

}
