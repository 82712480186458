// actions.js
import AWS from "aws-sdk";

const bucketName = "prod.glue.processing.dfpoc";
const folderPath = "decomposition/front_end_json_selection/";
const fileName = "form_data.py";
const objectKey = folderPath + fileName;

AWS.config.update({
  accessKeyId: "AKIAQML5EMUN5BTFWYEM",
  secretAccessKey: "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",
  region: "us-east-2",
});

const s3 = new AWS.S3();

export const uploadDecompositionProgress = (percentage) => ({
  type: "UPLOAD_DECOMPOSITION_PROGRESS",
  payload: percentage,
});

export const uploadDecompositionError = (error) => ({
  type: "UPLOAD_DECOMPOSITION_ERROR",
  payload: error,
});

export const uploadDecompositionSuccess = (location) => ({
  type: "UPLOAD_DECOMPOSITION_SUCCESS",
  payload: location,
});

export const jsonToS3 = (data) => {
  return async (dispatch) => {
    const params = {
      Bucket: bucketName,
      Key: objectKey,
      Body: data,
      ACL: "public-read",
      ContentType: "text/x-python",
    };

    try {
      const upload = s3.upload(params).on("httpUploadProgress", (progress) => {
        const { loaded, total } = progress;
        const percentage = Math.round((loaded / total) * 100);

        // Dispatch progress update to Redux
        dispatch(uploadDecompositionProgress(percentage));
      });

      const result = await upload.promise();

      console.log(`File uploaded successfully: ${result.Location}`);
      // Dispatch success and uploaded file location to Redux
      dispatch(uploadDecompositionSuccess(result.Location));
    } catch (error) {
      console.error(error);
      // Dispatch error to Redux
      dispatch(uploadDecompositionError(error.message));
    }
  };
};
