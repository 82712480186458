import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useSnackbar = () => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState({});

  const ShowSnackbar = (message, severity) => {
    setOptions({ message, severity });
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const SnackbarComponent = () => {
    if (!options.message || !options.severity) {
      return null; // Return null if no message or severity is set
    }

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
      <div>
      <Alert onClose={handleClose} severity={options.severity}>
          {options.message}
        </Alert>
      </div>
       
      </Snackbar>
    );
  };

  return { SnackbarComponent, ShowSnackbar };
};

export default useSnackbar;
