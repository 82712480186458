import React from "react";
import ProgressStepper from "../../../components/UploadProgressStepper/ProgressStepper";
import { Box, Paper, Typography } from "@mui/material";

const ProgressHandler = () => {
  return (
    <>
      <div >
        <Box pl={4} pr={4}>
        <Typography variant="h6">Progress</Typography>
          <Paper square elevation={0} sx={{ p: 3 }}>
            <ProgressStepper />
          </Paper>
        </Box>
      </div>
    </>
  );
};

export default ProgressHandler;
