import React, { useEffect, useState } from "react";
import "./HomeStyles.css";
import landingimg from "../../asserts/dfBackground.jpg";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import TopNavBar from "../nav/TopNav";

const Image = () => {
  return <img src={landingimg} />;
};
const Footer = (props) => {
  return <div className="footer">{props.content}</div>;
};
const Contents = (props) => {
  const {
    loginWithRedirect,
    getTokenSilently,
    isAuthenticated,
    logout,
    user,
    isLoading,
  } = useAuth0();
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
        navigate('/app/home/dashboard');       
      }
    const checkAuthentication = async () => {
      if (isAuthenticated) {
        setAuthenticated(true);
        navigate('/app/home/dashboard');   
      }
    };
    checkAuthentication();
  }, [isAuthenticated, navigate]);

  return (
    <div className="text">

      {/* <h2> {props.title} </h2>*/}
      <h3> {props.subtitle} </h3>
      {/* <img src={wav}></img> */}
      <p> {props.content} </p>
      {!authenticated ? (
        <>
          <button
            onClick={() => {
                console.log("clicked")
              loginWithRedirect({
                state: {
                  returnTo: "/app/home/dashboard",
                },
              })}
            }
          >
            Login
          </button>
        </>
      ) : (
        <>
          {user?.name == "admin@wavicledata.com" && (
            <div>
             
             
            <button onClick={() => logout()}>Log out</button>
             
            </div>
          )}
        </>
      )}
    </div>
  );
};
const LandingPage = () => {
  return (
    <>
      <section>
        <div className="section1">
          <Image />
          <div className="branding overlay">
          <Contents
              // title=""
              subtitle="forecasting Intelligence solutions"
              content="Powered by Wavicle"
            />
            {/* <img src={schedule} /> */}
           
            <Footer content="version 3.0.1" />
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingPage;
