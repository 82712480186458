export const CURRENT_ENVIRONMENT = "dev";

export const DomainId = {
    "dev" : process.env.REACT_APP_AUTH0_DOMAIN_ID,
    "prod" : process.env.REACT_APP_AUTH0_DOMAIN_ID,
    "localhost": process.env.REACT_APP_AUTH0_DOMAIN_ID,
};
export const ClientId = {
    "dev" : process.env.REACT_APP_AUTH0_CLIENT_ID,
    "prod" : process.env.REACT_APP_AUTH0_CLIENT_ID,
    "localhost": process.env.REACT_APP_AUTH0_CLIENT_ID,
};