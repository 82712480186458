import React, { useState, useEffect, useRef } from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Chart from "chart.js/auto";
import ScenarioAnalyzer from "./ScenarioAnalyzer";
import { Select, Card, Col, Input, InputNumber, Row } from "antd";
import "./styles.css";
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import { saveAs } from "file-saver";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
// import GetModel from "../../components/model";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import InformationTable from "./InformationTable";
import { GetTooltip } from "../../components/formFeilds/inputFeilds";
import { Button } from "@material-ui/core";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const { Option } = Select;
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const arr = [0, "8/16/2021", "8/23/2021", "8/30/2021", "9/6/2021", "9/13/2021"];
const meanLinePlugin = (chart) => {
  const dataset = chart.data.datasets[0];
  const data = dataset.data;

  // Calculate the mean value
  const meanValue = data.reduce((sum, value) => sum + value, 0) / data.length;

  // Draw the mean line
  const ctx = chart.ctx;
  const yAxis = chart.scales["y-axis-0"];
  const xPos = chart.chartArea.left;
  const yPos = yAxis.getPixelForValue(meanValue);
  ctx.save();
  ctx.beginPath();
  ctx.strokeStyle = "#000";
  ctx.moveTo(xPos, yPos);
  ctx.lineTo(chart.chartArea.right, yPos);
  ctx.stroke();
  ctx.restore();
};

const LineChart = ({ key, Data }) => {
  const dollarLabels = [
    "Value_Function",
    "inventory_cost",
    "inventory_carry_cost",
    "gross_margin",
  ];

  const lineColors = ["#FF5733", "#33FF57", "#5733FF", "#FF33A1", "#33A1FF"]; // Add your desired colors


const options = {
  responsive: true,
  stepSize: 500,
  beginAtZero: false,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: true,
      position: 'top', // Adjust the position as needed ('top', 'bottom', 'left', 'right')
    },
  },
  scales: {
    y: {
      beginAtZero: false,
      ticks: {
        maxTicksLimit: 6,
      }
    },
    x: {
      beginAtZero: true,
      ticks: {
        callback: function (a, b, c) {
          if (dollarLabels.some((label) => Data?.value.includes(label)))
            return "$" + Data?.chartData?.labels[a];
          else return Data?.chartData?.labels[a];
        },
        stepSize: 200,
        maxTicksLimit: 4,
      },
    },
  },
};


  if (Data?.chartData && Data?.chartData.datasets) {
    Data.chartData.datasets.forEach((dataset, index) => {
      dataset.backgroundColor = lineColors[index];
      dataset.borderColor = lineColors[index];
    });
  }

  if (Data?.chartData && Data?.chartData.labels) {
    Data.chartData.labels.unshift("0");
  }
  return (
    <Grid
      xs={12}
      sm={12}
      md={Data.length === 1 ? 12 : 6}
      lg={Data.length === 1 ? 12 : 4}
    >
      <Card className="Card" key={key}>
        <div
          className="LineChart"
          style={{ height: Data.length === 1 ? "100vh" : "auto" }}
        >
          <div>
            <label className="chartLabel">{Data?.title}</label>
            {/* {GetTooltip(Data?.tooltip)} */}
          </div>

          <Line
            style={{ zIndex: "1" }}
            options={options}
            data={Data?.chartData}
            width={80}
            height={50}
          />
        </div>
      </Card>
    </Grid>
  );
};


const ViewScenarioAnalyzer = () => {
  const analyzer = new ScenarioAnalyzer();

  const theme = useTheme();
  ////console.log(analyzer,"qazxsw23edcvfr45tgbnhy67ujm");
  const [seeds, setSeeds] = useState([1627, 2599, 2127, 2242, 2326]);

  const [data, setData] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  const [chartModel, setChartModel] = useState([]);
  const [optionModel, setOptionModel] = useState();
  /////////////////////////////

  const [chartDatalist, setChartDatalist] = useState([]);
  const [chartFilterDatalist, setChartFilterDatalist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [histogramData, setHistogramData] = useState([]);
  const [open, setIsOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const chartRef = useRef([]);

  var display_dict = [
    { id: 0, value: "Value_Function", tooltip: "your_tooltip_here", title: "Value Function" },
    { id: 1, value: "begin_inventory", tooltip: "your_tooltip_here", title: "begin inventory" },
    { id: 2, value: "end_inventory", tooltip: "your_tooltip_here", title: "end inventory" },
    { id: 3, value: "demand", tooltip: "your_tooltip_here", title: "demand" },
    { id: 4, value: "gross_margin", tooltip: "your_tooltip_here", title: "gross margin" },
    { id: 5, value: "units_sold", tooltip: "your_tooltip_here", title: "units sold" },
    { id: 6, value: "inventory_cost", tooltip: "your_tooltip_here", title: "inventory cost" },
    { id: 7, value: "inventory_carry_cost", tooltip: "your_tooltip_here", title: "inventory carry cost" },
    { id: 8, value: "lost_margin", tooltip: "your_tooltip_here", title: "lost margin" },
    { id: 9, value: "lost_sales", tooltip: "your_tooltip_here", title: "lost sales" },
    { id: 10, value: "stockout", tooltip: "your_tooltip_here", title: "stockout" },
  ];

  chartRef.current = Array(11)
    .fill(null)
    .map(() => {
      return document.createElement("canvas");
    });
  const density_config = require("./scenario_analyzer_density.json");
  const config_data = density_config["orders"]["data"][0];
  config_data["demand"] = density_config["demand"]["data"][0]["demand"];
  const density_config_sliced = density_config["demand_density_forecast"][
    "data"
  ].slice(0, 4000);
  var demandArray = new Array(4);
  for (var i = 0; i < demandArray.length; i++) {
    demandArray[i] = new Array();
    for (let j = i; j < 4000; j = j + 4) {
      demandArray[i].push(density_config_sliced[j]["yhat"]);
    }
  }
  const fieldsArray = density_config["orders"]["data"];
  //
  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
  const generateChart = () => {
    var newHistogramData = [];
    setIsLoading(true);
    for (let i = 0; i < chartRef.current.length; i++) {
      const currentRef = chartRef.current[i];
      if (currentRef) {
        newHistogramData = analyzer.generateHistogramData(
          config_data,
          seeds,
          display_dict[i].value,
          demandArray,
          fieldsArray
        );
        setHistogramData(newHistogramData);
        console.log(newHistogramData,"---------")
        let date = analyzer?.dates?.map((num) => Math.floor(num));

        const chartData = {
          title: display_dict[i].title,
          value:display_dict[i].value,
          tooltip:display_dict[i].tooltip,
          chartData: {
            labels: date,
            datasets: newHistogramData.returnData,
          },
        };
        setChartDatalist((chartDatalist) => {
          return [...chartDatalist, chartData];
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    generateChart();
  }, []);
  const handleSeedChange = (index, value) => {
    const newSeeds = [...seeds];
    newSeeds[index] = parseInt(value);
    setSeeds(newSeeds);
  };

  const handleUpdateClick = () => {
    setChartDatalist([]); //reset
    generateChart();
  };
  const handleModel = (data) => {
    ////console.log(data, "qwertyui");
    setModelOpen(true);
    setChartModel(data);
  };
  const handleExport = () => {
    let data = histogramData?.newConfigData;
    const jsonData = JSON.stringify(data);
    const blob = new Blob([jsonData], {
      type: "application/json;charset=utf-8",
    });

    saveAs(blob, "HistogramData.json");
  };
  const handleSelectChange = (values) => {
    setSelectedTitle(values);
    console.log(values);
  };
  const handleReset = () => {
    setSelectedTitle();
    setChartDatalist([]);
    generateChart();
  };

  const filteredChartDataList = chartDatalist.filter(
    (chartData, index) =>
      selectedTitle.length === 0 || selectedTitle.includes(display_dict[index].value)
  );
  
  //console.log(seeds);
  return (
    <div className="ViewScenarioAnalyzer">
      <div className="AnalyserContainer">
        <div className="AnalyserChart">
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2, p: 2 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",

                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">Dashboard</Typography>
                <div>
                  <Select
                    placeholder="Compare Filter"
                    style={{ minWidth: 240,marginRight:5 }}
                    value={selectedTitle}
                    onChange={handleSelectChange}
                    size="middle"
                    mode="multiple"
                    maxTagCount= "responsive"
                  >
                    {display_dict.map((item) => (
                      <Option key={item.value} value={item.value}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    onClick={handleUpdateClick}
                    variant="contained"
                    color="primary"
                    sx={{mr:2}}
                  >
                    Update Chart
                  </Button>
                </div>
              </div>

              <Grid
                container
                direction="row"
                // justifyContent="space-between"
                // alignItems="center"
              >
                {isLoading ? (
                  <>
                    <CircularProgress />{" "}
                  </>
                ) : (
                  <>
                    <br />
                    {/* <Grid item xs={12} md={6} lg={6}> */}
                    <div className="tableWrap">
                      <div className="tableCard">
                        <InformationTable seeds={seeds} setSeeds={setSeeds} />
                      </div>
                    </div>
                    <div className="setWidhth"></div>
                    {/* </Grid> */}
                    {filteredChartDataList.map((Data, index) => (
                      <>
                        {/* {Data?.chartData?.labels.length !== 1 && ( */}
                          <>
                            <LineChart key={index} Data={Data} />
                          </>
                        {/* )} */}
                      </>
                    ))}
                  </>
                )}
              </Grid>

              {/* </Main> */}
            </Paper>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ViewScenarioAnalyzer;
