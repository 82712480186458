import { Button } from "@mui/material";
import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Outlet, useNavigate } from "react-router-dom";
import { SelectedModalDashboard } from "./SelectedModalDashboard";
const openedMixin = (theme) => ({
    width: "10%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });
const StyledDiv = styled('div',{
  })(({ theme}) => ({
    width: "10%",
    flexShrink: 0,
    whiteSpace: "nowrap",
    display:"flex",
    flexDirection:"column",
    boxSizing: "border-box",
    ...(true && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    })
  }));
  const data = [
    { id: 1, label: 'EDA', link: "https://us-east-2.quicksight.aws.amazon.com/sn/embed/share/accounts/026569106715/dashboards/a7307cca-1bf4-414a-83b0-fcac7db3fbf8?directory_alias=wav-datascience" },
    { id: 2, label: 'Decomposition', link: "https://us-east-2.quicksight.aws.amazon.com/sn/embed/share/accounts/026569106715/dashboards/d6efc968-81ab-4db7-8f96-24b20ddb713d?directory_alias=wav-datascience" }
  ];

const ModelSelectionLayout = () => {
    const navigate=useNavigate()
    const [activeButton, setActiveButton] = useState(data[0].id); // Set the first button as active by default

    const handleClick=()=>{
        navigate('/app/dashboard/sample-modal/train-modal/filter-attributes')
    }
    const handleButtonClick = (buttonId) => {
      console.log(`Button ${buttonId} clicked`);
      setActiveButton(buttonId);
    };
  
    const activeButtonLink = activeButton ? data.find(button => button.id === activeButton).link : null;
  
  return (
    <div style={{display:"flex",flexDirection:"row"}}>
      <StyledDiv>
      {data.map(button => (
        <Button
        sx={{mt:1}}
          key={button.id}
          variant={activeButton === button.id ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleButtonClick(button.id)}
        >
          {button.label}
        </Button>
      ))}
      </StyledDiv>
      
     
      
      <Box component="main" sx={{ flexGrow: 1, p: 0 }} >
      <Button style={{position:"relative",float:"right", marginRight:"3rem" , marginTop:2}} onClick={handleClick} variant="contained">Continue</Button>
      {activeButtonLink && <SelectedModalDashboard link={activeButtonLink} />} 
      </Box>
    </div>
  );
};

export default ModelSelectionLayout;
