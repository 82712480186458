import React from "react";
import Auth0ProviderWithHistory from "./authentication/auth0/Auth0ProviderWithistory";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  RouterProvider,
} from "react-router-dom";
import { RenderRoutes } from "./router/authRoute";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingBar } from "./components/Loaders/CircularLoader";

function App() {
  const { isLoading, isAuthenticated } = useAuth0();
  console.log(isAuthenticated, "authenticated");
  if (isLoading) {
    return <LoadingBar />;
  }
  return (
    <div>
      <RouterProvider router={RenderRoutes} />
    </div>
  );
}

export default App;
