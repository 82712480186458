import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import { resetState, resetUploadState } from "../../redux/actions";
import { useDispatch } from "react-redux";

function Login() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
const dispatch=useDispatch()
  return (
    <div>
      {isAuthenticated ? (
        <button onClick={() =>{dispatch(resetState());dispatch(resetUploadState()); logout()}}>Log out</button>
      ) : (
        <button onClick={() => loginWithRedirect()}>Log in</button>
      )}
      // ... your other Login components and routes
    </div>
  );
}

export default Login;
