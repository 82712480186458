import React, { useState } from "react";
import { Grid, Box, Paper, Button, Divider, ButtonGroup } from "@mui/material";
import { Lock, Visibility } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { HeatmapChart } from '@carbon/charts-react'
// import '@carbon/charts-react/styles.css'
import { headMapOptions, heatMapData, alertCardsData, dashboardDataConstant, dashboardLinks, biasChartData, biasChartOption, mapeChartData, mapeChartOption } from "../../constants/dataConstants";
import CustomCard from "../../components/DashboardCards"
import { useNavigate } from "react-router";
import MuiIcon from "../../components/MuiIcon";
import { GroupedBarChart } from '@carbon/charts-react'
import { Space, Switch, Tabs, Select } from 'antd';
// import {Bar} from "react-chartjs-2"
import LandingPageChart from "./LandingPageChart";

import "./LandingPage.css";

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const timeLagOptions = [
    {
        label: "1",
        value: 1
    },
    {
        label: "2",
        value: 2
    },
    {
        label: "3",
        value: 3
    },
    {
        label: "4",
        value: 4
    }
]


export default function LandingPageDashboard() {
    const navigate = useNavigate();
    const [chartCheck, setChartCheck] = useState(false);
    const handleChartCheck = (checked) => {
        setChartCheck(checked);
    }
    const tabChange = (key) => {
        console.log(key);
    };
    let targetValue = 30;
    const datasetForChart2 = {
        datasets: [
            {
                type: 'bar',
                label: 'Bar Dataset',
                data: [32, 34,35],
                backgroundColor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return value >= targetValue ? '#FF0000' : '#12239E';
                },
                borderWidth: 1
            },
            {
                type: 'bar',
                label: 'Bar Dataset',
                data: [28, 38,50],
                backgroundColor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return value >= targetValue ? '#FF0000' : '#12239E';
                },
                borderWidth: 1
            },
            {
                type: 'bar',
                label: 'Bar Dataset',
                data: [35, 27],
                backgroundColor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return value >= targetValue ? '#FF0000' : '#12239E';
                },
                borderWidth: 1
            },
            {
                type: 'bar',
                label: 'Bar Dataset',
                data: [28, 38],
                backgroundColor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return value >= targetValue ? '#FF0000' : '#12239E';
                },
                borderWidth: 1
            },
            {
                type: 'bar',
                label: 'Bar Dataset',
                data: [25, 23],
                backgroundColor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return value >= targetValue ? '#FF0000' : '#12239E';
                },
                borderWidth: 1
            }
        ],
        labels: ['July', 'August','Sept']
    };

    let biasTargetValue = 10;
    let biasTargetValue2 = -10;
    const biasDatasetForChart2 = {
        datasets: [
            {
                type: 'bar',
                label: 'Bar Dataset',
                data: [7.20, -4.50],
                backgroundColor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return value >= biasTargetValue ? '#FF0000' : '#12239E';
                },
                borderWidth: 1
            },
            {
                type: 'bar',
                label: 'Bar Dataset',
                data: [7.20, -9.60],
                backgroundColor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return value >= biasTargetValue ? '#FF0000' : '#12239E';
                },
                borderWidth: 1
            },
            {
                type: 'bar',
                label: 'Bar Dataset',
                data: [54.10, 63.40],
                backgroundColor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return value >= biasTargetValue ? '#FF0000' : '#12239E';
                },
                borderWidth: 1
            },
            {
                type: 'bar',
                label: 'Bar Dataset',
                data: [5.10, 13.20],
                backgroundColor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return value >= biasTargetValue ? '#FF0000' : '#12239E';
                },
                borderWidth: 1
            },
            {
                type: 'bar',
                label: 'Bar Dataset',
                data: [-2.70, 0.00],
                backgroundColor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return value >= biasTargetValue ? '#FF0000' : '#12239E';
                },
                borderWidth: 1
            }
        ],
        labels: ['July', 'August']
    };

    const chartjsOptions = {
        plugins: {
            legend: {
                display: false
            }
        }
    }
    const items = [
        {
            key: '1',
            label: 'MAE',
            children:
                <LandingPageChart dataset={datasetForChart2} targetValue={targetValue} />
            // <Bar data={datasetForChart2} options={chartjsOptions} />
            // <GroupedBarChart
            //     data={mapeChartData ? mapeChartData : []}
            //     options={mapeChartOption ? mapeChartOption : {}}
            // ></ GroupedBarChart>,
        },
        {
            key: '2',
            label: 'BIAS',
            children: 
            <LandingPageChart dataset={biasDatasetForChart2} targetValue={biasTargetValue} targetValue2={biasTargetValue2} />
            // <GroupedBarChart
            //     data={biasChartData}
            //     options={biasChartOption}
            // ></ GroupedBarChart>,
        }
    ];
    return (
        <div>
            <Grid container >
                <Grid container xs={5} style={{ paddingRight: "1%" }}>
                    {/* <Switch checkedChildren="MAPE" unCheckedChildren="BIAS" onChange={handleChartCheck} checked={chartCheck} /> */}
                    <Grid container xs={12} sx={{ alignContent: "flex-end", alignItems: "flex-end" }}>
                        <Grid item xs={8}>
                            Weekly Champion Model Performance
                        </Grid>
                        <Grid item xs={4}>
                            Time Lag
                            <Select
                                style={{ marginLeft: "5%" }}
                                defaultValue={3}
                                placeholder="Select Material Type"
                                className="chartSelector"
                                options={timeLagOptions}
                            />
                        </Grid>
                        {/* <ButtonGroup variant="outlined" aria-label="Basic button group">
                            <Button onClick={() => handleChartCheck(true)}>Mape</Button>
                            <Button onClick={() => handleChartCheck(false)}>Bias</Button>
                        </ButtonGroup> */}
                        <Grid item xs={12}>
                            <Tabs defaultActiveKey="1" items={items} onChange={tabChange} />
                        </Grid>
                    </Grid>
                    {/* {
                        chartCheck ?
                            <GroupedBarChart
                                data={mapeChartData ? mapeChartData : []}
                                options={mapeChartOption ? mapeChartOption : {}}
                            ></ GroupedBarChart> :
                            <GroupedBarChart
                                data={biasChartData}
                                options={biasChartOption}
                            ></ GroupedBarChart>
                    } */}
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid container xs={5} style={{ padding: "1% 2% 1% 2%" }} spacing={3}>
                    {dashboardDataConstant.map((data, index) => (
                        <Grid item xs={6}>
                            <div style={{ position: "relative" }}>
                                <CustomCard type={data.type} cardStyle={data.cardStyle} cardData={data} />
                                {/* Conditionally render overlay for last four cards */}
                                {index >= dashboardDataConstant.length - 4 && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            backgroundColor: "rgba(0,0,0,0.1)", // Transparent overlay color
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            zIndex: 1, // Ensure overlay is above the card
                                        }}
                                    >
                                        {/* You can replace this with your desired content */}
                                        <Lock style={{ color: "white" }} />
                                    </div>
                                )}
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid item xs={2} zeroMinWidth style={{ marginLeft: "1%" }}>
                    <Item style={{ fontWeight: "bold", width: "100%", color: "#dae0e6" }}>Alerts that require you're attention</Item>
                    {alertCardsData?.map(data =>
                        <Item style={{ width: "100%" }}><CustomCard cardType="alert" cardData={data} cardStyle={data.cardStyle} /></Item>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ borderTop: "1px solid #B7B7B7", marginTop: "1%", paddingTop: "2%" }}>
                <Grid container spacing={2} xs={7} style={{ borderRight: "1px solid #B7B7B7", padding: "1%" }}>
                    <>
                        {dashboardLinks?.map(data =>
                            <Grid item xs={4}>
                                <Paper sx={{ padding: "2%", textAlign: "center", width: '100%', textTransform: "capitalize", cursor: "pointer", height: "100%", minHeight: "70px", alignContent: "center", alignItems: "center", justifyContent: "center" }} onClick={() => navigate(data.linkTo)}>
                                    {data.title}
                                </Paper>
                            </Grid>
                        )
                        }
                    </>
                </Grid>
                <Grid item xs={5} style={{ padding: '1%', display: "flex", width: "100%", justifyContent: "center" }} spacing={1}>
                    {/* <div className="overlay"> */}
                    <Paper sx={{ padding: "2%", textAlign: "center", width: '100%', textTransform: "capitalize", cursor: "not-allowed", height: "100%", minHeight: "100px", display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", background: "#d9d9d9" }}>Business Decision Driving Visuals and Information</Paper>
                    {/* </div> */}
                </Grid>
            </Grid>
        </div>
    )
}