import { makeStyles, Theme } from '@material-ui/core/styles';
export const listWorkspaceStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    flexBasis: 'auto',
    flexDirection: 'row',
    justifyContent: "space-between",
    width: '97%',
    paddingTop: '1%'

  },
  title: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    paddingTop: '1%',
    paddingLeft: '1.25rem',
    minHeight: '3rem',
    background: '#F8F8F8',
    alignItems: 'center',
  },
  exportButton: {

  },
  buttonAlignment: {
    paddingTop: '1%'
  },
  layout: {
    background: '#F8F8F8',
    height: "92vh"
  },
  breadcrumbs: {
    display: 'flex',
    marginLeft: '-7.8rem',
    marginTop: '-2rem',

  },
  input: {
    padding: theme.spacing(1),
  },
  button: {

  },
  subheading: {
    fontSize: '17px',
    fontWeight: '500',
    fontFamily: 'Poppins',
    paddingTop: '.5rem',
    paddingLeft: '1.25rem',
    width: '100%',
    minHeight: '2rem',
    background: '#F8F8F8',
    display: 'flex',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#ffffff',
  },
  modelForeCastLayout: {
    background: '#F8F8F8',
    minHeight: "92vh",
    height:"100%"
  },
}));
