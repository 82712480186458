import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  CheckTick,
  CircularIndeterminate,
  DisabledIcon,
  ShowErrorIcon,
} from "../Loaders/CircularLoader";
import { useDispatch, useSelector } from "react-redux";
// import { GlueJobStatus } from "../../aws/GlueJobStatus/Status1";
import { GlueJob2Status } from "../../aws/GlueJobStatus/Status2";
import { resetUploadState } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import useSnackbar from "../Alert/UseSnackbar";
const steps = [
  {
    label: "Uploading to S3",
    completedLabel: "File Uploaded to S3",
    description: "The file is being moved to S3",
    errorDescription: "There was an error uploading the file to S3",
  },
  {
    label: "Setting up Dashboard",
    completedLabel: "DashboardUpdated",
    description: "We are getting your dashboard ready",
    errorDescription: "There was an error setting up the dashboard",
  },
];


export default function ProgressStepper() {
  const [activeStep, setActiveStep] = React.useState();
  // const [status, setStatus] = React.useState("");
  const [status2, setStatus2] = React.useState("");
  const [errorStep, setErrorStep] = React.useState(-1);

  const { SnackbarComponent, ShowSnackbar } = useSnackbar();
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const s3UploadStatus = useSelector((state) => state.UploadFileReducer); // Assuming s3UploadStatus is stored in Redux
  // const glueJob1Status = useSelector(state => state.glueJob1Status); // Assuming glueJob1Status is stored in Redux
  // const glueJob2Status = useSelector(state => state.glueJob2Status); // Assuming glueJob2Status is stored in Redux

  // const [glueJob1Success, setGlueJob1Success] = React.useState(false);
  const [glueJob2Success, setGlueJob2Success] = React.useState(false);
  const isS3Uploaded = s3UploadStatus.uploadSuccess;
  React.useEffect(() => {
    if (isS3Uploaded) {
      // If status is true, set anotherState to true after 5 seconds
      const timeoutId = setTimeout(() => {
        setStatus2(true);
        ShowSnackbar('Dashboard Updated', 'success');
      }, 5000);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [isS3Uploaded]);
  
  React.useEffect(() => {
      const isGlueJob2Completed = status2 === true;
  
      // Here we ensure that GlueJob won't be rerun if already succeeded.
      // if (isGlueJob1Completed) {
      //   setGlueJob1Success(true);
      // }
      if (isGlueJob2Completed) {
        setGlueJob2Success(true);
      }
  
      if (status2 === "FAILED") {
        ShowSnackbar('Glue Job 2 failed', 'error');
        setErrorStep(2);
      } 
      else if (isGlueJob2Completed && !glueJob2Success) {
        ShowSnackbar('File process completed', 'success');
        dispatch(resetUploadState());
        setActiveStep(2);
      } 
      // else if (isGlueJob1Completed && !glueJob1Success) {
      //   ShowSnackbar('File moved to Redshift', 'success');
      //   setActiveStep(2);
      // } 
      else if (isS3Uploaded) {
        ShowSnackbar('File Uploaded to S3', 'success');
        setActiveStep(1);
      }
  }, [s3UploadStatus, status2]);
  

  const handleReset = () => {
    dispatch(resetUploadState());
    navigate('/app/upload/target-variable');
  };

  const handleDashboard =()=>{
    dispatch(resetUploadState());
    navigate('/app/visualizations/dashboard/');
  }

  const getStepIcon = (index) => {
    if (index === errorStep) {
      return <ShowErrorIcon />; // Replace with the error icon component
    } else if (index < activeStep ) {
      return <CheckTick />; // Replace with the tick icon component
    } else if (index === activeStep) {
      return <CircularIndeterminate />; // Replace with the loader icon component
    } else {
      return <DisabledIcon />; // Replace with the grey icon component
    }
  };

  return (
    <>
      <>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={index} completed={index < activeStep}>
              <StepLabel StepIconComponent={()=>getStepIcon(index)}>
                {index === activeStep ? step.label : step.completedLabel}
              </StepLabel>
              <StepContent>
              {index === errorStep ? step.errorDescription : step.description}
                <Box sx={{ mb: 2 }}>
                  <></>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <SnackbarComponent/>
        {/* {activeStep === 1 && (
          <GlueJobStatus status={status} setStatus={setStatus} />
        )} */}
        {/* {activeStep === 1 && (
          <GlueJob2Status status={true} setStatus={true} />
        )} */}
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>The data is processed and the dashboards are updated</Typography>
            <Button variant="contained" onClick={handleDashboard} sx={{ mt: 1, mr: 1 }}>
              Take to Dashboard
            </Button>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </>
    </>
  );
}
