import { applyMiddleware } from "redux";
import { legacy_createStore as createStore } from 'redux';
import { persistStore } from "redux-persist";

import storage from "redux-persist/lib/storage";

import { composeWithDevTools } from "redux-devtools-extension";

import thunk from "redux-thunk";

import { createBrowserHistory } from "history";

import { routerMiddleware } from "react-router-redux";
import reducers from './reducers';


const history = createBrowserHistory();

export const store = createStore(
  reducers,

  undefined,

  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
);

export const persistor = persistStore(store);

export { history };

export default store;
