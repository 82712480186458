export const updateTemporalData = (data) => ({
    type: 'UPDATE_TEMPORAL_DATA',
    payload: data
  });
  
  export const toggleModalSuiteTraining = (model, value) => ({
    type: 'TOGGLE_MODAL_SUITE_TRAINING',
    payload: { model, value }
  });
  
  export const updateModelSuiteName = (name) => ({
    type: 'UPDATE_MODEL_SUITE_NAME',
    payload: name
  });

  export const updateAttributesData = (data) => ({
    type: 'UPDATE_ATTRIBUTES_DATA',
    payload: data
  });
  
  export const updateState = (newState) => ({
    type: 'UPDATE_STATE',
    payload: newState
  });
  
  export const resetState = () => ({
    type: 'RESET_STATE'
  });
  