import * as Icons from '@mui/icons-material';

const MuiIcon = ({ iconName, iconSize, iconColor }) => {
    // Dynamically import the icon component based on the iconName prop
    const IconComponent = Icons[iconName];

    if (!IconComponent) {
        // Handle case where the icon name is invalid
        return <div>Invalid icon name</div>;
    }

    return <IconComponent sx={{ fontSize: iconSize ? iconSize : "small", iconColor: iconColor ? iconColor : "#666666" }} />;
};

export default MuiIcon;