import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import { styled, useTheme } from '@mui/material/styles';

const BreadcrumbsContainer = styled(Breadcrumbs)(({ theme }) => ({
  padding: theme.spacing(1),
  background: '#f1f1f1',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
}));

const BreadcrumbLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
}));

const BreadcrumbIcon = styled(HomeIcon)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  color: "#1976d2"
}));

const CustomBreadcrumbs = ({homeUrl,count}) => {
  const theme = useTheme();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(Boolean);
  const pathSkipCount = count; // Number of initial path segments to skip (e.g., "/app/dashboard")

  return (
    <BreadcrumbsContainer separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      <BreadcrumbLink to={homeUrl}>
        <BreadcrumbIcon />
        Home
      </BreadcrumbLink>
      {pathnames.map((name, index) => {
        if (index < pathSkipCount) return null; // Skip initial path segments
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        const breadcrumbText = isLast ? (
          <Typography key={name} color="textPrimary">
            {name}
          </Typography>
        ) : (
          <BreadcrumbLink key={name} to={routeTo}>
            {name}
          </BreadcrumbLink>
        );
        return breadcrumbText;
      })}
    </BreadcrumbsContainer>
  );
};

export default CustomBreadcrumbs;
