import { Button, FormControl, InputLabel, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Form } from "antd";

import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";
import { NumberField } from "@material-ui/core";
import { Radio } from "antd";
import { Row, Col } from "antd";
import { TextField, Select } from "@mui/material";
import { Checkbox, Input } from "antd";
import { useState } from "react";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  StyledDatePicker,
  StyledSelect,
  StyledTextField,
} from "../../../../components/inputFields/FormFields";
import { useDispatch } from "react-redux";
import { jsonToS3 } from "../../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  input: {
    padding: theme.spacing(1),
  },
}));

export const TemporalInfo = ({
  TemporalDataForm,
  handleDateChange,
  handleTemporalData,
}) => {
  console.log(TemporalDataForm);
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6">Temporal Information</Typography>{" "}
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className={classes.input}>
            <StyledDatePicker
              label="Start Date"
              format="DD-MM-YYYY"
              value={
                TemporalDataForm?.Start
                  ? dayjs(TemporalDataForm.Start, "DD-MM-YYYY")
                  : null
              }
              onChange={handleDateChange("Start")}
            />
          </div>
          <div className={classes.input}>
            <StyledDatePicker
              label="End Date"
              format="DD-MM-YYYY"
        value={TemporalDataForm?.End ? dayjs(TemporalDataForm.End, 'DD-MM-YYYY') : null}
        onChange={handleDateChange("End")}
            />
          </div>
          <div className={classes.input}>
            <StyledDatePicker
              label="Split Date"
              format="DD-MM-YYYY"
        value={TemporalDataForm?.Split ? dayjs(TemporalDataForm.Split, 'DD-MM-YYYY') : null}
        onChange={handleDateChange("Split")}
            />
          </div>
        </LocalizationProvider>
        <div className={classes.input}>
          <StyledTextField
            label="Table name"
            value={TemporalDataForm?.TableName}
            onChange={handleTemporalData("TableName")}
          />
        </div>
        <div className={classes.input}>
          <StyledTextField
            label="Config path"
            value={TemporalDataForm?.ConfigPath}
            onChange={handleTemporalData("ConfigPath")}
          />
        </div>
        <div className={classes.input}>
          <StyledTextField
            label="Forecast window"
            type="number"
            value={TemporalDataForm?.ForcastWindow}
            onChange={handleTemporalData("ForcastWindow")}
          />
        </div>
        <div className={classes.input}>
          <StyledTextField
            label="Decision window"
            type="number"
            value={TemporalDataForm?.DecisionWindow}
            onChange={handleTemporalData("DecisionWindow")}
          />
        </div>
      </FormControl>
    </>
  );
};

export const FilterAttributes = ({AttributesForm,handleAttributes,TemporalDataForm,
handleTemporalData}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch =useDispatch();

  const handleModalDashboardClick = () => {
   let data= 'query= "category" '
    dispatch(jsonToS3(data))
    navigate("/app/dashboard/model-selection");
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6"></Typography>{" "}
        <Button onClick={handleModalDashboardClick} type="text">
          Select modal dashboard
        </Button>
      </div>
      <div>
        <FormControl fullWidth>
          <div className={classes.input}>
            <InputLabel id="Product-label">Attributes</InputLabel>
            <StyledSelect
              labelId="Product-label"
              id="demo-simple-select"
              value={AttributesForm?.query}
              label="Product"
              onChange={handleAttributes}
            >
              <MenuItem defaultValue="Product" value="Product">
                Product
              </MenuItem>
              <MenuItem value="Category">Category</MenuItem>
            </StyledSelect>
          </div>
          <div className={classes.input}>
          <StyledTextField
            label="Period"
            type="number"
            value={TemporalDataForm?.Period}
            onChange={handleTemporalData("Period")}
          />
        </div>
        <div className={classes.input}>
          <StyledTextField
            label="Customer lev"
            type="number"
            value={TemporalDataForm?.CustomerLev}
            onChange={handleTemporalData("CustomerLev")}
          />
        </div>
        <div className={classes.input}>
          <StyledTextField
            label="Product lev"
            type="number"
            value={TemporalDataForm?.ProductLev}
            onChange={handleTemporalData("ProductLev")}
          />
        </div>
        <div className={classes.input}>
          <StyledTextField
            label="Geo lev"
            type="number"
            // value={TemporalDataForm?.GeoLev}
            // onChange={handleTemporalData("GeoLev")}
          />
        </div>
        <div className={classes.input}>
          <StyledTextField
            label="Measure"
            value={TemporalDataForm?.Measure}
            onChange={handleTemporalData("Measure")}
          />
        </div>
        <div className={classes.input}>
          <label>Distinct rows: {"    "}</label>
          <Radio.Group
            value={TemporalDataForm?.Distinct}
            onChange={handleTemporalData("Distinct")}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div> 
        
        </FormControl>
      </div>
    </>
  );
};

export const ModalSuite = ({
  ModalSuiteForm,
  TemporalDataForm,
handleTemporalData,
  checkedValues,
  isTextFields,
  handleCheckboxChange,
  handleTextfieldChange,
  checkboxOptions,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6">Modal Suite</Typography>
      <div className={classes.input}>
          <StyledTextField
            label="Chain"
            value={TemporalDataForm?.Chain}
            onChange={handleTemporalData("Chain")}
          />
        </div>
        <div className={classes.input}>
          <StyledTextField
            label="Store"
            type="number"
            value={TemporalDataForm?.Store}
            onChange={handleTemporalData("Store")}
          />
        </div>
        <div className={classes.input}>
          <StyledTextField
            label="Product id"
            value={TemporalDataForm?.ProductId}
            onChange={handleTemporalData("ProductId")}
          />
        </div>
      {checkboxOptions.map((option, index) => (
        <div key={option.value} index={index} className={classes.input}>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <div>
                {" "}
                <label>
                  {option.label}
                  {" : "}
                </label>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div>
                <Checkbox
                  value={option.value}
                  options={option}
                  onChange={handleCheckboxChange}
                  checked={ModalSuiteForm[option.value]}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div>
                <Input
                  style={{ width: "100%" }}
                  placeholder={`Enter text for ${option.label}`}
                  disabled={!ModalSuiteForm[option.value]}
                  // value={inputValues[option.value]}
                  // onChange={(event) => handleTextfieldChange(event, option.value)}
                />
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
};

export const ModalDetails = ({ ModalSuiteDetailsForm, handleModelSuiteName }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6">Modal suite details</Typography>
      <div className={classes.input}>
        <StyledTextField
          id="outlined-multiline-static"
          label="Model suite name"
          value={ModalSuiteDetailsForm?.modelSuiteName}
          onChange={handleModelSuiteName("modelSuiteName")}
        />
      </div>
      <div className={classes.input}>
        <StyledTextField
          id="outlined-multiline-static"
          label="Model description"
          // value={ModalSuiteDetailsForm?.suiteDescription}
          // onChange={handleTemporalData("suiteDescription")}
          multiline
          rows={4}
        />
      </div>
    </>
  );
};
