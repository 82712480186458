import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import Auth0ProviderWithHistory from "./authentication/auth0/Auth0ProviderWithistory";
import "./index.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </PersistGate>
  </Provider>
);
