import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { RouteData } from "./routes";
import LandingPage from "../layouts/home/LandingPage";
import Login from "../layouts/home/Login";
import ProtectedRoute from "./ProtectedRoute";
import React from "react";
import TopNavBar from "../layouts/nav/TopNav";
import SideNavBar from "../layouts/nav/sideNav";
import ModalDashboardLayout from "../layouts/dashboard/SampleModal/ModalDashboardLayout";
import ModelSelectionLayout from "../layouts/dashboard/ModelSelection/ModelSelectionLayout";
import ModalTable from "../components/ModalTable/ModalTable";
import ModalDialogLayout from "../layouts/dashboard/SampleModal/ModalDialog/ModalDialogLayout";
import FileUploadLayout from "../layouts/FileUpload/FileUploadLayout";
import TargetVariables from "../layouts/FileUpload/Upload/TargetVariables";
import ProgressHandler from "../layouts/FileUpload/ProgressDashBoard/ProgressHandler";
import VisualizationsLayout from "../layouts/Visualization/VisualizationsLayout";
import LIstDashboards, {
  Category,
  Item,
} from "../layouts/Visualization/LIstDashboards";
import ScenarioAnalyzerLayout from "../layouts/ScenarioAnalyzer/ScenarioAnalyzerLayout";
import ScenarioAnalyzerLayoutWithApi from "../layouts/ScenarioAnalyzer/ScenarioAnalyzerLayoutWithApi";
import ViewScenarioAnalyzer from "../layouts/ScenarioAnalyzer/ViewScenarioAnalyzer";
import ViewScenarioAnalyzerWithApi from "../layouts/ScenarioAnalyzer/ViewScenarioAnalyzerWithApi";
import SamplelistLayout from "../layouts/SampleModel/SamplelistLayout";
import SampleTable from "../layouts/SampleModel/SampleList";
import ModelDetailsList from "../layouts/ModelDetails/ModelDetails";
import ChampionChalleger from "../layouts/ChampionChalleger/ChampionChalleger";
import ForecastListLayout from "../layouts/Forecasts/ForecastListLayout";
import ForecastList from "../layouts/Forecasts/ForecastList";
import ForecastChart from "../layouts/Forecasts/DataCharts/forecastChart";
import Overlay from "../layouts/ScenarioAnalyzer/Overlay";
import PowerBiChart from "../components/PowerBiDashboard/PowerBiChart";
import LandingPageDashboard from "../layouts/LandingPage/LandingPageDashboard";
import LandingPageLayout from "../layouts/LandingPage/LandingPageLayout";
import ModelForecast from "../layouts/ModelForecast/ModelForeCast";
import ModelForecastLayout from "../layouts/ModelForecast/ModelForecastLayout";

export const RenderRoutes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" exact element={<TopNavBar />}>
        <Route index element={<LandingPage />} />
      </Route>
      <Route path="app" exact element={<SideNavBar />}>
        {/* <Route path="sample" element={<ModelDetailsList />}></Route> */}
        {/* <Route path="dashboard" element={<SamplelistLayout />}>
          <Route path="sample-modal" element={<SampleTable />}>
            <Route path="create/:page" element={<ModalDialogLayout />} />
          </Route>
          <Route path="sample-modal/:modelId" element={<ModelDetailsList />} />
          <Route
            path="sample-modal/:modelId/:suite"
            element={<ChampionChalleger />}
          />
        </Route>
        <Route path="forecast" element={<ForecastListLayout />}>
          <Route path="forecast-list" element={<ForecastList />} />
          <Route path="forecast-list/:data" element={<ForecastChart/>} />
        </Route> */}
        {/* <Route path="dashboard" element={<ModalDashboardLayout />}>
          <Route path="sample-modal" element={<ModalTable />}>
            <Route path="train-modal" element={<ModalDialogLayout />}>
              <Route path=":page" element={<ModalDialogLayout />} />
            </Route>
          </Route>
          <Route path="model-selection" element={<ModelSelectionLayout />} />
        </Route> */}
        <Route path="home" element={<LandingPageLayout />}>
          <Route path="dashboard" element={<LandingPageDashboard />} />
        </Route>
        <Route path="upload" element={<FileUploadLayout />}>
          <Route index path="target-variable" element={<TargetVariables />} />
          <Route path="progress" element={<ProgressHandler />} />
        </Route>
        <Route path="visualizations" element={<VisualizationsLayout />}>
          <Route path="dashboard" element={<LIstDashboards />}>
            <Route path=":id" element={<LIstDashboards />} />
          </Route>
          <Route path="quicksightDashboard" element={<PowerBiChart />}>
            <Route path=":id" element={<PowerBiChart />} />
          </Route>
        </Route>
        <Route path="scenario-analyzer" element={<ScenarioAnalyzerLayout />}>
          <Route path="dashboard" element={<ViewScenarioAnalyzer />} />
        </Route>
        <Route path="scenario-analyzer-with-api" element={<ScenarioAnalyzerLayoutWithApi />}>
          <Route path="dashboard" element={<ViewScenarioAnalyzerWithApi />} />
        </Route>
        <Route path="model-forecast" element={<ModelForecastLayout />}>
          <Route path="dashboard" element={<ModelForecast />} />
        </Route>
      </Route>
      <Route path="*" element={<Login />} />
    </>
  )
);
