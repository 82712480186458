
// Auth0 credentials
export const AUTH0_DOMAIN = 'dev-rd6qfaos13emrsuj.us.auth0.com';
export const AUTH0_CLIENT_ID = "nPh4spLrSQCkOe9Je2WZDFjG0UiekqkZ";
//aws
export const ACCESS_KEY_ID= 'AKIAQML5EMUN5BTFWYEM';
export const SECRECT_ACCESS_KEY= '/NoSuDZtK9kKikbEvmXxmOt5aqewAAuenKmKO297';
export const BUCKET_NAME='dfpocrawflatfile';
export const BUCKET_REGION='us-east-2';
//quicksight
export const EXPLORATORY_ANALYSIS="https://us-east-2.quicksight.aws.amazon.com/sn/embed/share/accounts/026569106715/dashboards/43ba8dbf-ba44-490b-a18c-2fdd5e4d7ca6?directory_alias=wav-datascience"
export const ANALYSIS="https://us-east-2.quicksight.aws.amazon.com/sn/embed/share/accounts/026569106715/dashboards/dea9faa6-824c-4930-837b-b3745d90c64e?directory_alias=wav-datascience"





//
export const FETCH_DASHBOARD = "https://nsekli9mj8.execute-api.us-west-2.amazonaws.com/scenario-analyzer/my-scen-analyzer";
export const MATERIALANDINVENTORYLIST='https://nsekli9mj8.execute-api.us-west-2.amazonaws.com/scenario-analyzer/my-scen-analyzer?material_id=materialIdParam&inventory_list=inventoryListParam&order_needs_to_be_placed=orderneedstobeplaced'