import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { StyledTextField } from "../../../components/inputFields/FormFields";
import { listWorkspaceStyles } from "../../dashboard/SampleModal/ModalListStyles";
import UploadFileBtn from "../../../components/UploadProgressStepper/UploadFileBtn";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "../../../redux/actions";
import { useNavigate } from "react-router-dom";

const TargetVariables = () => {
  const classes = listWorkspaceStyles();
  const fields = [
    {
      id: "project_name",
      label: "Please name your project",
      type: "TextField",
    },
    {
      id: "data_col_name",
      label: "What is the column name of your datetime variable?",
      type: "TextField",
    },
    {
      id: "target_col_name",
      label: "What is the column name of your target variable?",
      type: "TextField",
    }
  ];
  const navigate=useNavigate()
  const dispatch = useDispatch();
  const selectedFile = useSelector(state => state.UploadFileReducer.selectedFile)
  console.log(selectedFile);

  const hanldeContinueClick = () => {
    dispatch(uploadFile());
    navigate('/app/upload/progress');
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, p: 2 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",

              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">Target variable</Typography>
            <div>
              <UploadFileBtn/>
              <Button
                variant="contained"
                onClick={hanldeContinueClick}
                className={classes.button}
                disabled={!selectedFile? true:false}
                sx={{mr: 1 }}
              >
                {" "}
                Continue
              </Button>
            </div>
          </div>
          <div>
            <FormControl fullWidth>
              {fields.map((field) => (
                <div className={classes.input}>
                  <StyledTextField
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    // type={field.type}
                    fullWidth
                    // Add any additional props or event handlers you need
                  />
                </div>
              ))}
            </FormControl>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default TargetVariables;
