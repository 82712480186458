const initialState = {
  selectedFile: null,
  uploadSuccess: false,
  uploadProgress: 0,
  uploadFailed: false,
};

export const UploadFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        uploadSuccess: true,
        uploadFailed: false,
      };
    case 'UPLOAD_FAILURE':
      return {
        ...state,
        uploadFailed: true,
      };
    case 'UPLOAD_PROGRESS':
      return {
        ...state,
        uploadProgress: action.payload,
      };
    case 'SELECT_FILE':
      return {
        ...state,
        selectedFile: action.payload,
      };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};
