import React, { useState } from "react";
import { Table, InputNumber, Divider, Card } from "antd";
import "./styles.css";
const dataSource = [
  {
    forecastDate: "16-08-2021",
    expectedValue: 1491.11,
    actualDemand: 1924,
    wholesale: 1.56,
    retail: 2.34,
    discount: 0.18,
    beginInventory: 2242,
    resetOrders: 1924,
    promiseDate: "8-16-21",
    orders: 1000,
  },
  {
    forecastDate: "23-08-2021",
    expectedValue: 1694.9,
    actualDemand: 2587,
    wholesale: 1.56,
    retail: 2.34,
    discount: 0.18,
    beginInventory: 0,
    resetOrders: 2587,
    promiseDate: "8-23-21",
    orders: 1000,
  },
  {
    forecastDate: "30-08-2021",
    expectedValue: -130.08,
    actualDemand: 2217,
    wholesale: 1.56,
    retail: 2.34,
    discount: 0.18,
    beginInventory: 0,
    resetOrders: 2217,
    promiseDate: "8-30-21",
    orders: 1000,
  },
  {
    forecastDate: "06-09-2021",
    expectedValue: 1785.45,
    actualDemand: 2497,
    wholesale: 1.56,
    retail: 2.34,
    discount: 0.18,
    beginInventory: 0,
    resetOrders: 2497,
    promiseDate: "9-6-21",
    orders: 2497,
  },
  {
    forecastDate: "13-09-2021",
    expectedValue: 751.5,
    actualDemand: 3242,
    wholesale: 1.56,
    retail: 2.34,
    discount: 0.18,
    beginInventory: 0,
    resetOrders: 1421.7,
    promiseDate: "9-13-21",
    orders: 1421.7,
  },
];
const OrdersColumn = ({ index , seeds, setSeeds}) => {
  const [orders, setOrders] = useState(dataSource[index].resetOrders);

  const handleSeedChange = (value) => {
    const newSeeds = [...seeds];
    newSeeds[index] = parseInt(value);
    setSeeds(newSeeds);
  };

  return (
    <InputNumber
      value={seeds[index]}
      onChange={handleSeedChange}
      style={{ width: "30" }}
      size="small"
    />
  );
};
const InformationTable = ({seeds, setSeeds}) => {

  const columns = [
    {
      title: "Expected Value",
      dataIndex: "expectedValue",
      key: "expectedValue",

      width: 150,
    },
    {
      title: "Forecast Date",
      dataIndex: "forecastDate",
      key: "forecastDate",
      width: 150,
    },
    {
      title: "Actual Demand",
      dataIndex: "actualDemand",
      key: "actualDemand",
      width: 150,
    },
    {
      title: "Wholesale",
      dataIndex: "wholesale",
      key: "wholesale",
      width: 150,
    },
    {
      title: "Retail",
      dataIndex: "retail",
      key: "retail",
      width: 150,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: 150,
    },
    {
      title: "Begin Inventory",
      dataIndex: "beginInventory",
      key: "beginInventory",
      width: 150,
    },
    {
      title: "Reset Orders",
      dataIndex: "resetOrders",
      key: "resetOrders",
      width: 150,
    },
    {
      title: "Promise Date",
      dataIndex: "promiseDate",
      key: "promiseDate",
      width: 150,
    },
    {
      title: "Orders", // add a custom title for the "Orders" column
      dataIndex: "resetOrders", // use the "resetOrders" property as the data index
      width: 200,
      key: "resetOrders",
      render: (_, record, index) => <OrdersColumn index={index} seeds={seeds} setSeeds={setSeeds}/>, // render the custom component
    },
  ];
  return (
    <div className="my-table-wrapper">
    
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey={(record) => record.forecastDate} // set the "forecastDate" as the row key
          pagination={false} // hide pagination
        />
      
    </div>
  );
};

export default InformationTable;
