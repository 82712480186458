import React from 'react';
import './styles.css'; // Import your component styles
import ViewScenarioAnalyzer from './ViewScenarioAnalyzer';

class Overlay extends React.Component {
  render() {
    return (
      <div>
        {/* Your running component */}
        <div id="running-component">
          {/* Content of your running component goes here */}
          <ViewScenarioAnalyzer />
        </div>

        {/* Gray overlay with "Coming Soon" message */}
        <OverlayC />
      </div>
    );
  }
}

class OverlayC extends React.Component {
  render() {
    return (
      <div id="gray-overlay">
        <div id="coming-soon-message">
        
            <h2>Coming Soon...</h2>
            <p>Stay tuned for updates on our upcoming scenario analyzer.</p>

          
        </div>
      </div>
    );
  }
}

export default Overlay;
