import * as d3 from "d3";
var nj = require("numjs");

function minBetweenNumjsArray(array1, array2) {
  //
  let return_array = [];
  var min = 0;
  var array1_val = 0;
  var array2_val = 0;
  for (let i = 0; i < array1.size; i++) {
    array1_val = array1.get(i);
    array2_val = array2.get(i);
    if (array1_val < array2_val) {
      min = array1_val;
    } else {
      min = array2_val;
    }
    return_array.push(min);
  }
  var nj_array = nj.float64(return_array);
  return nj_array;
}

class ScenarioAnalyzer {
  constructor() {
    this.dates = [];
    this.begin_inventory = 2000;
    this.demand = null;
    this.wholesale = null;
    this.retail = null;
    this.discount = null;
    this.margin = null;
    this.orders = null;
    this.available_inventory = null;
    this.sales = null;
    this.units_sold = null;
    this.end_inventory = null;
    this.stockout = null;
    this.lost_sales = null;
    this.gross_margin = null;
    this.inventory_carry_cost = null;
    this.lost_margin = null;
    this.inventory_cost = null;
    this.demand_array = null;
  }

  //Calc for forecast_window 0
  init_calc(config_data, seeds, demandArray = null) {

    this.begin_inventory = nj.float64(Array(1000).fill(2000)); //get this from json scenario_analyzer_density.json
    if (demandArray == null) {
      this.demand_array = Array(1000).fill(1924);
    }
    this.demand = nj.float64(this.demand_array);
    this.orders = nj.float64(Array(1000).fill(seeds[0]));
    //self.wholesale = self.get_pricing(calc_period)
    //self.retail = self.get_pricing(calc_period) 
    //self.discount = self.get_pricing(calc_period)
    //self.margin = self.retail - self.wholesale
    // available = self.begin_inventory + orders
    // self.sales = np.minimum(self.demand, available)
    // self.end_inventory = available - self.sales
    // self.stockout = self.demand - self.sales
    // self.lost_sales = self.stockout * self.wholesale
    // self.gross_margin = self.sales * self.margin
    // self.inventory_carry_cost = self.discount * self.wholesale * (self.begin_inventory + self.end_inventory) / (
    //             2 * 52)
    // self.lost_margin = self.stockout * self.margin
    // self.inventory_cost = self.wholesale * (self.begin_inventory + self.end_inventory) / 2
    // self.sales_dollars = self.sales * self.retail
    this.wholesale = nj.float64(Array(1000).fill(config_data["wholesale"]));
    this.retail = nj.float64(Array(1000).fill(config_data["retail"]));
    this.discount = nj.float64(Array(1000).fill(config_data["discount"]));
    this.margin = this.retail.subtract(this.wholesale);
    this.available_inventory = this.begin_inventory.add(this.orders);
    this.sales = minBetweenNumjsArray(this.demand, this.available_inventory);
    this.end_inventory = this.available_inventory.subtract(this.sales);
    this.stockout = this.demand.subtract(this.sales);
    this.lost_sales = this.stockout.multiply(this.wholesale);
    this.gross_margin = this.sales.multiply(this.margin);
    this.inventory_carry_cost = this.discount
      .multiply(this.wholesale)
      .multiply(this.begin_inventory.add(this.end_inventory))
      .divide(104);
    this.lost_margin = this.stockout.multiply(this.margin);
    this.inventory_cost = (this.wholesale.multiply(this.begin_inventory.add(this.end_inventory))).divide(2);
    this.units_sold = this.sales.multiply(this.retail);

    this.Value_Function = this.gross_margin
      .subtract(this.inventory_carry_cost)
      .subtract(this.lost_margin);
    //Trasition from scalar to array
    this.begin_inventory = this.end_inventory; // EQ-2
  }
  //
  //
  //
  forecast_window_calc(fields_array, seeds, forecast_window) {
    this.demand = nj.float64(this.demand_array);
    //get orders from seeds
    this.orders = nj.float64(Array(1000).fill(seeds[forecast_window]));
    //do static_calc to make wholesale, retail, discountand margin arrays instead of scalars
    this.wholesale = nj.float64(
      Array(1000).fill(fields_array[forecast_window]["wholesale"])
    );
    this.retail = nj.float64(
      Array(1000).fill(fields_array[forecast_window]["retail"])
    );
    this.discount = nj.float64(
      Array(1000).fill(fields_array[forecast_window]["discount"])
    );
    this.margin = this.retail.subtract(this.wholesale);
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!THIS IS INVENTORY CALC
    // Update data
    this.available_inventory = this.orders.add(this.begin_inventory);
    this.sales = minBetweenNumjsArray(this.demand, this.available_inventory);
    this.end_inventory = this.available_inventory.subtract(this.sales);
    this.stockout = this.demand.subtract(this.sales);
    // Set values
    this.gross_margin = this.sales.multiply(this.margin);
    this.units_sold = this.sales;
    this.lost_margin = this.stockout.multiply(this.margin);
    this.lost_sales = this.stockout.multiply(this.wholesale);
    this.inventory_cost = (this.wholesale.multiply(this.begin_inventory.add(this.end_inventory))).divide(2);
    this.inventory_carry_cost = this.discount
      .multiply(this.wholesale)
      .multiply(this.begin_inventory.add(this.end_inventory))
      .divide(104);
    this.begin_inventory = this.end_inventory;
    this.Value_Function = this.gross_margin
      .subtract(this.inventory_carry_cost)
      .subtract(this.lost_margin);
  }
  //
  //GET Data
  //
  getData(newConfigData, metric_to_return) {
    var newData = [];
    var metric_to_returnData = [];
    var metric_to_returnBin;
    var d3metric_to_return;
    function* values(obj) {
      for (let prop of Object.keys(obj)) // own properties, you might use
        // for (let prop in obj)
        yield obj[prop];
    }
    let result = Array.from(Object.keys(newConfigData[0]));
    if (result.includes(metric_to_return)) {
      var arr = []
      for (let i = 0; i < newConfigData.length;i++)
      {
        arr.push(newConfigData[i][metric_to_return]["selection"]["data"])
      }
      var combArray = [...(arr[0]), ...(arr[1]), ...(arr[2])];
      var domain = [Math.min(...combArray), Math.max(...combArray)];
      var rowData = [];
      for (let j = 0; j < newConfigData.length; j++) {
        rowData = [];
        metric_to_returnData = newConfigData[j][metric_to_return]["selection"][
          "data"
        ].map((d) => d);
        metric_to_returnBin = d3.bin().domain(domain).thresholds(25);
        d3metric_to_return = metric_to_returnBin(metric_to_returnData);

        for (let i = 0; i < d3metric_to_return.length; i++) {
          if (d3metric_to_return[i].length > 2) {
            rowData.push(d3metric_to_return[i].length);
          } else {
            rowData.push(0);
          }
        }
        this.clearDates();
        this.generateHistoGramXAxisValues(d3metric_to_return);
        newData.push({
          label: `DataSet${j + 1}`,
          data: rowData,
          borderColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
            Math.random() * 256
          )}, ${Math.floor(Math.random() * 256)})`,
          tension: 0.1,
        });
      }
    }
    return newData;
  }


  generateHistoGramXAxisValues(histArray) {
    for (let i = 0; i < histArray.length; i++) {
      this.dates.push(histArray[i]["x0"]);
    }
  }

  //main
  generateHistogramData(
    config_data,
    seeds,
    metric_to_return,
    demandArray1,
    fields_array
  ) {
    var newConfigData = [];
    var returnConfigData = [];
    const newData = [];
    this.init_calc(config_data, seeds);
    var output_init_calc_dict = {
      available_inventory : this.available_inventory,
      sales: this.sales,
      demand : this.demand,
      orders: this.orders,
      begin_inventory: this.begin_inventory,
      end_inventory: this.end_inventory,
      stockout: this.stockout,
      margin: this.margin,
      gross_margin: this.gross_margin,
      units_sold: this.units_sold,
      lost_margin: this.lost_margin,
      lost_sales: this.lost_sales,
      inventory_cost: this.inventory_cost,
      inventory_carry_cost: this.inventory_carry_cost,
      retail: this.retail,
      wholesale: this.wholesale,
      Value_Function: this.Value_Function,
    };
    newConfigData.push(output_init_calc_dict);
    for (let forecast_window = 1; forecast_window < 4; forecast_window++) {
      this.demand_array = demandArray1[forecast_window - 1];
      this.forecast_window_calc(fields_array, seeds, forecast_window);
      var output_per_forecast_window_dict = {
        available_inventory : this.available_inventory,
        sales: this.sales,
        demand : this.demand,
        orders: this.orders,
        begin_inventory: this.begin_inventory,
        end_inventory: this.end_inventory,
        stockout: this.stockout,
        margin: this.margin,
        gross_margin: this.gross_margin,
        units_sold: this.units_sold,
        lost_margin: this.lost_margin,
        lost_sales: this.lost_sales,
        inventory_cost: this.inventory_cost,
        inventory_carry_cost: this.inventory_carry_cost,
        retail: this.retail,
        wholesale: this.wholesale,
        Value_Function: this.Value_Function,
      };
      newConfigData.push(output_per_forecast_window_dict);
      returnConfigData.push(output_per_forecast_window_dict);
    }
    //console.log("newConfigData")
    //console.log(newConfigData)
    //console.log("returnConfigData")
    //console.log(returnConfigData)
    //console.log("******")
    //console.log(metric_to_return)
    // Write data in 'Output.txt' .
    var returnData = this.getData(returnConfigData, metric_to_return);
    let obj={
      returnData:returnData,
  newConfigData:newConfigData
  }
      //console.log(returnData,newConfigData ,"123456789")
  
      return obj;
  }


  generateHistogramDates(configArray) {
    const today = new Date();

    for (let i = 0; i < configArray.length; i++) {
      const dateString = new Date(configArray[i][0]).toLocaleDateString(
        "en-US"
      );
      this.dates.push(dateString);
    }
  }
  clearDates() {
    this.dates = [];
  }
}

export default ScenarioAnalyzer;
