import { Button } from "@mui/material";
import { useRef, useState } from "react";
import { setUploadFile } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const UploadFileBtn = () => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedFileName, setSelectedFileName] = useState("");
    
  const selectedFile = useSelector(state => state.UploadFileReducer.selectedFile)
  const handleUploadClick = () => {
    const file = fileInputRef.current.files[0];
    if (!file) {
      setSelectedFileName("");
      dispatch(setUploadFile(null));
      console.log("No file selected");
      return;
    }
    setSelectedFileName(file.name);
    const allowedFileTypes = [".csv"];
    const fileType = file.name
      .substring(file.name.lastIndexOf("."))
      .toLowerCase();
    if (!allowedFileTypes.includes(fileType)) {
      console.log("Only CSV files are allowed");
      return;
    }

    // Perform file upload or further processing
    console.log("Selected file:", file);
    dispatch(setUploadFile(file));
  };

  return (
    <>
      <span style={{ fontStyle: "italic", marginRight: "10px" }}>
        {selectedFile && selectedFile.name}
      </span>
      <label htmlFor="file-upload">
        <Button variant="contained" component="label" sx={{ mr: 1 }}>
          {selectedFile ? "Change File" : "Upload"}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".csv"
            onChange={handleUploadClick}
            onClick={(event) => {
              event.target.value = null; // Reset the value so that the same file can be selected again
            }}
          />
        </Button>
      </label>
    </>
  );
};
export default UploadFileBtn;
