import React from 'react'
import { Box, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { listWorkspaceStyles } from '../dashboard/SampleModal/ModalListStyles';

const ModelForecastLayout = () => {
    const classes = listWorkspaceStyles();

  return (
    <div className={classes.modelForeCastLayout}>
      <Typography className={classes.title}>Model Performance and Selection</Typography>
      <Box component="main" sx={{ flexGrow: 1, p: 3, height:"100%" }}>
        <Outlet />
      </Box>
    </div>
  )
}

export default ModelForecastLayout;