import React, { useState } from "react";
import { Grid, Paper, Divider, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Stars } from '@mui/icons-material';
import MuiIcon from '../../components/MuiIcon';
import { Select, Checkbox, InputNumber } from "antd";
import ModelForeCastTable from "./ModelForecastTable";
import { headMapOptions, heatMapData } from "../../constants/dataConstants";
import { HeatmapChart } from '@carbon/charts-react'
import ModelForeCastSideTable from "./ModelForecastTableSite";
import Snackbar, { snackbarClasses } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function createData(id, challengers, wape, rmse, smape, mae, bias) {
    return {
        id,
        challengers,
        wape,
        rmse,
        smape,
        mae,
        bias
    };
}

const mfMainTableRows = [
    createData(1, 'ARIMA', 53.0, 21.0, 74.0, 18.0, 21.0),
    createData(2, 'ETS', 78.0, 21.0, 74.0, 12.0, 29.0),
    createData(3, 'Prophet', 78.0, 20.0, 71.0, 17.0, 22.0),
    createData(4, 'MQCNN', 57.0, 24.0, 85.0, 13.0, 20.0),
    createData(5, 'iTransformer', 54.0, 23.0, 93.0, 18.0, 25.0),
    createData(6, 'DeepAR', 58.0, 19.0, 73.0, 15.0, 29.0),
    createData(7, 'Temporal Fusion Transformer', 58.0, 24.0, 71.0, 13.0, 23.0),
];

const staticOptions = [
    {
        value: 'jack',
        label: 'Jack',
    },
    {
        value: 'lucy',
        label: 'Lucy',
    },
    {
        value: 'Yiminghe',
        label: 'yiminghe',
    },
    {
        value: 'disabled',
        label: 'Disabled',
        disabled: true,
    },
]

const staticAlgorithmOptions = [
    {
        value: 'WAPE',
        label: 'WAPE',
    },
    {
        value: 'RMSE',
        label: 'RMSE',
    },
    {
        value: 'SMAPE',
        label: 'SMAPE',
    },
    {
        value: 'MAE',
        label: 'MAE',
    },
    {
        value: 'BIAS',
        label: 'BIAS',
    }
]

const seasonalityStaticOptions = [
    {
        value: 'Daily',
        label: 'Daily',
    },
    {
        value: 'Hourly',
        label: 'Hourly',
    },
    {
        value: 'Weekly',
        label: 'Weekly',
    },
    {
        value: 'Monthly',
        label: 'Monthly',
    }
]

const mfHeadCells = [
    {
        id: 'challengers',
        numeric: false,
        disablePadding: true,
        label: 'Challengers',
    },
    {
        id: 'wape',
        numeric: true,
        disablePadding: false,
        label: 'WAPE%',
    },
    {
        id: 'rmse',
        numeric: true,
        disablePadding: false,
        label: 'RMSE%',
    },
    {
        id: 'smape',
        numeric: true,
        disablePadding: false,
        label: 'SMAPE%',
    },
    {
        id: 'mae',
        numeric: true,
        disablePadding: false,
        label: 'MAE',
    },

    {
        id: 'bias',
        numeric: true,
        disablePadding: false,
        label: 'BIAS%',
    },
];

const mfSideTableHeadCells = [
    {
        id: 'exogenousVariables',
        numeric: false,
        disablePadding: true,
        label: 'Exogenous Variables',
    }
];

function createData1(id, exogenousVariables) {
    return {
        id,
        exogenousVariables
    };
}

const mfSideTableRows = [
    createData1(1, 'Holidays'),
    createData1(2, 'Weather'),
    createData1(3, 'ABC'),
    createData1(4, 'ZXY'),
];

const staticHierarchialOption = [
    {
        value: 'Product',
        label: 'Product',
    },
    {
        value: 'Region',
        label: 'Region',
    }
]

const staticOptimizationMetric = [
    {
        value: 'nmse',
        label: 'NMSE',
    },
    {
        value: 'mae',
        label: 'MAE',
    },
    {
        value: 'smape',
        label: 'SMAPE',
    },
    {
        value: 'wape',
        label: 'WAPE',
    }
]


export default function ModelForeCast() {
    let initValue = {
        reconcilation: false,
        futureForecastPeriod: 12,
        validationPeriod: 8,
        foreCastAggregationLevel: "Weekly",
        optimizationMetric: "nmse",
        reconcilation: false,
        heirarchialLevel: "",
        densityForecast: true,
        densityForecastSampleSize: 100
    };
    const defaultExogenousValue = 1;
    const [selectedId, setSelected] = useState();
    const [exogenousSelectedId, setExogenousSelected] = useState();
    const [defaultChampion, setDefaultChampion] = useState("DeepAR");
    const [reconcilation, setReconcilation] = useState(false);
    const [densityForecast, setDensityForecast] = useState(false);
    const [formValue, setFormValue] = useState(initValue);
    const [snackBarOpen, setSnackBar] = React.useState(false);

    const ModelForecastCard = ({ iconName, content, title, iconSize }) => {
        return (
            <Paper style={{ height: "100%", padding: "5%" }}>
                <div style={{ width: "100%", textAlign: "center" }}>{title}</div>
                <Grid container spacing={2} sx={{ padding: "2%" }}>
                    <Grid item xs={2}>
                        <div style={{ width: "100%", margin: "auto", display: "flex", justifyContent: "center", textAlign: "center" }}>
                            <MuiIcon iconName={iconName} iconSize={iconSize} />
                        </div>
                    </Grid>
                    <Grid item xs={9} >
                        <div style={{ width: "100%", margin: "auto", display: "flex", justifyContent: "center", textAlign: "center" }}>
                            {content}
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    const loadDropdown = () => {
        return <Select
            defaultValue="RMSE"
            style={{
                width: '100%'
            }}
            // onChange={handleChange}
            options={staticAlgorithmOptions}
        />
    }

    const checkboxChange = (e) => {
        console.log(e.target.id)
        let newFormValue = { ...formValue };
        newFormValue[e.target.id] = e.target.checked;
        setFormValue(newFormValue);
        if (e.target.id == 'reconcilation') {
            setReconcilation(e.target.checked)
        } else {
            setDensityForecast(e.target.checked)
        }
    };

    const inputNumberChange = (value, name) => {
        let newFormValue = { ...formValue };
        newFormValue[name] = value;
        setFormValue(newFormValue);
    }

    const handleReset = () => {
        setFormValue(initValue)
        setSelected()
        setExogenousSelected()
    }

    const handleSelect = (value, name) => {
        let newFormValue = { ...formValue };
        newFormValue[name] = value;
        setFormValue(newFormValue);
    }

    const handleApply = (newState) => {
        setSnackBar(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBar(false);
    };


    return (
        <div style={{ height: "100%" }}>
            <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                    <div style={{ width: "100%", background: "#5878EE", textAlign: "center", padding: ".5%", color: "#FFFFFF", fontWeight: "bold" }}>
                        Control Panel
                    </div>
                </Grid>
                <Grid container xs={6.4} spacing={5} sx={{ paddingTop: "1%" }}>
                    <Grid item xs={6}>
                        <ModelForecastCard title={selectedId ? "Selected Champion" : "Default Champion Forecast"} iconName="Stars" content={selectedId || selectedId == 0 ? mfMainTableRows[selectedId].challengers : defaultChampion} iconSize="3em" />
                    </Grid>
                    <Grid item xs={6}>
                        <ModelForecastCard title="Accuracy Driver" iconName="TrackChanges" content={loadDropdown()} iconSize="3em" />
                    </Grid>
                    <Grid item xs={12}>
                        <ModelForeCastTable getSelectedValue={setSelected} rows={mfMainTableRows} headCells={mfHeadCells} defaultChampion={defaultChampion} selectedValue={selectedId} />
                    </Grid>
                </Grid>
                <Grid item xs={0.2}>
                    <Divider orientation="vertical" sx={{ height: "100%" }} />
                </Grid>
                <Grid container xs={5.6} sx={{ paddingLeft: "1%" }} spacing={2}>
                    <Grid item xs={6}>
                        Future Forecast Period
                        <InputNumber min={1} max={100} defaultValue={12} value={formValue['futureForecastPeriod']} onChange={(value) => { inputNumberChange(value, "futureForecastPeriod") }} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={6}>
                        Validation Period
                        <InputNumber min={1} max={100} defaultValue={8} onChange={(value) => { inputNumberChange(value, 'validationPeriod') }} value={formValue['validationPeriod']} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={6}>
                        Forecast Aggregation Level
                        <Select
                            style={{
                                width: '100%'
                            }}
                            defaultValue="Weekly"
                            // onChange={handleChange}
                            options={seasonalityStaticOptions}
                            value={formValue['foreCastAggregationLevel']}
                            onChange={(value) => handleSelect(value, "foreCastAggregationLevel")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        Optimization Metric
                        <Select
                            style={{
                                width: '100%'
                            }}
                            defaultValue="nmse"
                            // onChange={handleChange}
                            options={staticOptimizationMetric}
                            value={formValue['optimizationMetric']}
                            onChange={(value) => handleSelect(value, "optimizationMetric")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        Reconcilation
                        <Checkbox id="reconcilation" onChange={checkboxChange} style={{ marginLeft: "4%" }} checked={formValue['reconcilation']} />
                    </Grid>
                    <Grid item xs={6}>
                        Hierarchial Level
                        <Select
                            style={{
                                width: '100%'
                            }}
                            // onChange={handleChange}
                            disabled={!formValue['reconcilation']}
                            options={staticHierarchialOption}
                            value={formValue['heirarchialLevel']}
                            onChange={(value) => handleSelect(value, "heirarchialLevel")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        Density Forecast
                        <Checkbox id="densityForecast" onChange={checkboxChange} style={{ marginLeft: "4%" }} checked={formValue['densityForecast']} />
                    </Grid>
                    <Grid item xs={6}>
                        Density Forecast Sample Size
                        <InputNumber min={100} max={1000} defaultValue={100} onChange={(value) => { inputNumberChange(value, "densityForecastSampleSize") }} style={{ width: "100%" }} disabled={!formValue['densityForecast']} value={formValue['densityForecastSampleSize']} />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ width: "100%" }}>
                            <ModelForeCastSideTable getSelectedValue={setExogenousSelected} rows={mfSideTableRows} headCells={mfSideTableHeadCells} selectedValue={exogenousSelectedId} defaultValue={defaultExogenousValue} />
                        </div>
                    </Grid>
                    <Grid item xs={8} />
                    <Grid item xs={2}>
                        <div style={{ width: "100%" }}>
                            <Button variant="outlined" sx={{ background: "white" }} onClick={handleReset}>Reset</Button>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{ width: "100%" }}>
                            <Button variant="outlined" sx={{ background: "white" }} onClick={() => { handleApply({ vertical: 'top', horizontal: 'right' }) }}>Apply</Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar open={snackBarOpen} anchorOrigin={{vertical:"top",horizontal:"right"}} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Updated Applied
                </Alert>
            </Snackbar>
        </div>
    )
}