import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloudUploadSharpIcon from "@mui/icons-material/CloudUploadSharp";
import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import HomeIcon from '@mui/icons-material/Home';
import SpeedIcon from '@mui/icons-material/Speed';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import { NavLink, Outlet } from "react-router-dom";
import "./styles.css"
import { useAuth0 } from "@auth0/auth0-react";
import { resetState, resetUploadState } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { Grid, Avatar } from "@mui/material";
import MuiIcon from "../../components/MuiIcon";
import {Input} from 'antd';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideNavBar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  const Navigation = [
    // {
    //   id: "sample",
    //   label: "Sample",
    //   to: "/app/dashboard/sample-modal",
    //   icon: <HomeIcon color="primary" />,
    // },{
    //   id: "forecast",
    //   label: "Forecsast",
    //   to: "/app/forecast/forecast-list",
    //   icon: <ModelTrainingIcon color="primary" />,
    // },
    {
      id: "home",
      label: "Home",
      to: "/app/home/dashboard",
      icon: <HomeIcon color="primary" />,
    },
    {
      id: "upload",
      label: "Upload",
      to: "/app/upload/target-variable",
      icon: <CloudUploadSharpIcon color="primary" />,
    },
    {
      id: "visualizations",
      label: "visualizations",
      to: "/app/visualizations/dashboard/ForecastInsightDashboard",
      icon: <DashboardSharpIcon color="primary" />,
    },
    // {
    //   id: "scenarioanalyzer",
    //   label: "Scenario Analyzer ",
    //   to: "/app/scenario-analyzer/dashboard",
    //   icon: <LeaderboardIcon color="primary" />,
    // },
    {
      id: "scenarioanalyzerwithapi",
      label: "Scenario Analyzer New",
      to: "/app/scenario-analyzer-with-api/dashboard",
      icon: <LeaderboardIcon color="primary" />,
    },
    {
      id: "modelperformance",
      label: "Model Performance",
      to: "/app/model-forecast/dashboard",
      icon: <SpeedIcon color="primary" />
    }
  ];

  const handleLogout = () => {
    dispatch(resetState());
    dispatch(resetUploadState());
    logout({ returnTo: window.location.origin })
    // Redirect to the logout page or perform any other actions
  };

  const BottomNavigation = [
    {
      id: "logout",
      label: "Logout",
      to: "/",
      onClick: handleLogout,
      icon: <LogoutSharpIcon color="secondary" />,
    },
  ];




  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} color="default">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography noWrap component="div" sx={{ width: "100%" }}>
            <Grid container xs={12} spacing={4}>
              <Grid item xs={2}><img
                className="img-logo"
                style={{ maxWidth: "7rem" }}
                src={require("../../asserts/wavicle.png")}
              ></img></Grid>
              <Grid item xs={2}><span style={{color:"#BDBDBD"}}>Current Period:</span><span style={{color:"#094780"}}>April 2024</span></Grid>
              <Grid item xs={2}><span style={{color:"#BDBDBD"}}>Latest Actuals:</span><span style={{color:"#094780"}}>March 2024</span></Grid>
              <Grid item xs={2}><span style={{color:"#BDBDBD"}}>Last Date Refresh:</span><span style={{color:"#094780"}}>04/05/2024</span></Grid>
              <Grid item xs={2}><Input  suffix={<MuiIcon iconName="Search" />} /></Grid>
              <Grid item xs={2} >
                <div style={{display:"flex",flexDirection:"row"}}>
                <span style={{width:"20%", paddingTop:"1%", color:"#094780"}}><MuiIcon iconName={"NotificationsNone"} iconColor={"#094780"} iconSize={"1.5em"} /></span>
                <span style={{width:"20%", paddingTop:"1%", color:"#094780"}}><MuiIcon iconName={"QuestionMark"} iconColor={"#094780"} iconSize={"1.5em"}  /></span>
                <span style={{width:"40%"}}><Avatar sx={{color:"#094780"}}><MuiIcon iconName={"PersonOutline"} iconColor={"#094780"} iconSize={"1.5em"}  /></Avatar></span>
                </div>
                </Grid>
            </Grid>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          {Navigation.map((nav, index) => (
            <ListItem key={nav.id} disablePadding sx={{ display: "block" }}>
              <NavLink to={nav.to}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {nav.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={nav.label}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ))}
        </List>
        <div style={{ height: "12rem" }}></div>
        <Divider />
        <List>
          {BottomNavigation.map((nav, index) => (
            <ListItem key={nav.id} disablePadding sx={{ display: "block" }}>
              {/* <NavLink to={nav.to}> */}
              <ListItemButton
                onClick={nav.onClick}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {nav.icon}
                </ListItemIcon>
                <ListItemText
                  primary={nav.label}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
              {/* </NavLink> */}
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
