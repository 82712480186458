import React, { useState, useEffect, useRef } from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Chart from "chart.js/auto";
import ScenarioAnalyzer from "./ScenarioAnalyzer";
import { Select, Card, Col, Input, Row, Switch } from "antd";
import {
    CircularProgress,
    Grid,
    Paper,
    Typography,
    FormControl, InputLabel, MenuItem,
    InputNumber
} from "@mui/material";
import { Line, Bar } from "react-chartjs-2";
import { saveAs } from "file-saver";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
// import GetModel from "../../components/model";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import InformationTable from "./InformationTable";
import { GetTooltip } from "../../components/formFeilds/inputFeilds";
import { Button } from "@material-ui/core";
import { dataToComplete, histData, histChartOptions, barChartOptions, lineChartOptions, materialListDetails, editableAllowedScenarioAnalyzer, saLineChartOptions, saHistChartOptions } from "../../constants/dataConstants"
// import { Histogram, DensitySeries, BarSeries,  XAxis, YAxis } from '@data-ui/histogram';
import { HistogramChart, SimpleBarChart, LineChart } from '@carbon/charts-react';
import MuiSelect from '@mui/material/Select';
import axios from 'axios';
import { FETCH_DASHBOARD, MATERIALANDINVENTORYLIST } from "../../constants/urlconstants";
import { replaceMultipleStringValues } from "../../utils/constantFunctions";
import EditableComponent from "../../components/EditableComponent";
import ScenarioAnalyserTable from "./ScenarioAnalyserTable";

import '@carbon/charts-react/styles.css'
import "./styles.css";

const drawerWidth = 240;


const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

const { Option } = Select;
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const arr = [0, "8/16/2021", "8/23/2021", "8/30/2021", "9/6/2021", "9/13/2021"];
const meanLinePlugin = (chart) => {
    const dataset = chart.data.datasets[0];
    const data = dataset.data;

    // Calculate the mean value
    const meanValue = data.reduce((sum, value) => sum + value, 0) / data.length;

    // Draw the mean line
    const ctx = chart.ctx;
    const yAxis = chart.scales["y-axis-0"];
    const xPos = chart.chartArea.left;
    const yPos = yAxis.getPixelForValue(meanValue);
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = "#000";
    ctx.moveTo(xPos, yPos);
    ctx.lineTo(chart.chartArea.right, yPos);
    ctx.stroke();
    ctx.restore();
};

// const LineChart = ({ key, Data }) => {
//     const dollarLabels = [
//         "Value_Function",
//         "inventory_cost",
//         "inventory_carry_cost",
//         "gross_margin",
//     ];

//     const lineColors = ["#FF5733", "#33FF57", "#5733FF", "#FF33A1", "#33A1FF"]; // Add your desired colors


//     const options = {
//         responsive: true,
//         stepSize: 500,
//         beginAtZero: false,
//         maintainAspectRatio: true,
//         plugins: {
//             legend: {
//                 display: true,
//                 position: 'top', // Adjust the position as needed ('top', 'bottom', 'left', 'right')
//             },
//         },
//         scales: {
//             y: {
//                 beginAtZero: false,
//                 ticks: {
//                     maxTicksLimit: 6,
//                 }
//             },
//             x: {
//                 beginAtZero: true,
//                 ticks: {
//                     callback: function (a, b, c) {
//                         if (dollarLabels.some((label) => Data?.value.includes(label)))
//                             return "$" + Data?.chartData?.labels[a];
//                         else return Data?.chartData?.labels[a];
//                     },
//                     stepSize: 200,
//                     maxTicksLimit: 4,
//                 },
//             },
//         },
//     };


//     if (Data?.chartData && Data?.chartData.datasets) {
//         Data.chartData.datasets.forEach((dataset, index) => {
//             dataset.backgroundColor = lineColors[index];
//             dataset.borderColor = lineColors[index];
//         });
//     }

//     if (Data?.chartData && Data?.chartData.labels) {
//         Data.chartData.labels.unshift("0");
//     }
//     return (
//         <Grid
//             xs={12}
//             sm={12}
//             md={Data.length === 1 ? 12 : 6}
//             lg={Data.length === 1 ? 12 : 4}
//         >
//             <Card className="Card" key={key}>
//                 <div
//                     className="LineChart"
//                     style={{ height: Data.length === 1 ? "100vh" : "auto" }}
//                 >
//                     <div>
//                         <label className="chartLabel">{Data?.title}</label>
//                         {/* {GetTooltip(Data?.tooltip)} */}
//                     </div>

//                     <Line
//                         style={{ zIndex: "1" }}
//                         options={options}
//                         data={Data?.chartData}
//                         width={80}
//                         height={50}
//                     />
//                 </div>
//             </Card>
//         </Grid>
//     );
// };

function calculateEqualParts(numbers, numParts) {
    const min = Math.min(...numbers);
    const max = Math.max(...numbers);
    const range = (max - min) / numParts;

    const parts = [];
    for (let i = 0; i < numParts; i++) {
        if (i != 0)
            parts.push(min + i * range);
    }
    parts.push(max); // Add the last boundary as max

    return parts;
}

function splitAndCount(numbers, numParts) {
    const parts = calculateEqualParts(numbers, numParts);
    const ranges = [];
    const counts = [];
    // Calculate range boundaries
    for (let i = 0; i < parts.length - 1; i++) {
        ranges.push([parts[i], parts[i + 1]]);
        counts.push(0);
    }

    // Count numbers in each range
    numbers.forEach(number => {
        for (let i = 0; i < ranges.length; i++) {
            if (number >= ranges[i][0] && number <= ranges[i][1]) {
                counts[i]++;
                break;
            }
        }
    });

    return { counts, parts };
}





// const ResponsiveHistogram = (({ parentWidth, parentHeight, ...rest }) => {
//     return (
//         <Histogram
//             width={200}
//             height={200}
//             {...rest}
//         />
//     )
// })

function createData(id, forecastDate, beginning_inventory_distribution, demand_distribution, forecastedMinDemand, forecastedMaxDemand, pre_orders_yet_to_arrive_in_quantities, promisedDate, wholesale_price, retail_price, discount, promotion, inventory_cost_distrbution, inventory_carry_cost_distribution) {
    return {
        id,
        forecastDate,
        beginning_inventory_distribution,
        demand_distribution,
        forecastedMinDemand,
        forecastedMaxDemand,
        pre_orders_yet_to_arrive_in_quantities,
        promisedDate,
        wholesale_price,
        retail_price,
        discount,
        promotion,
        inventory_cost_distrbution,
        inventory_carry_cost_distribution
    };
}

const scenarioAnalyzerTableHeader = [
    {
        id: 'forecastDate',
        disablePadding: true,
        label: 'Forecast Date',
    },
    {
        id: 'beginning_inventory_distribution',
        numeric: true,
        disablePadding: false,
        label: 'Opening Inventory Levels',
    },
    {
        id: 'demand_distribution', // mean roundoff
        numeric: true,
        disablePadding: false,
        label: 'Forecast Point Demand',
    },
    {
        id: 'forecastedMinDemand',
        numeric: true,
        disablePadding: false,
        label: 'Forecasted Min Demand',
    },
    {
        id: 'forecastedMaxDemand',
        numeric: true,
        disablePadding: false,
        label: 'Forecasted Max Demand',
    },
    {
        id: 'pre_orders_yet_to_arrive_in_quantities',
        numeric: true,
        disablePadding: false,
        label: 'Incoming Inventory',
        type:"input"
    },
    {
        id: 'promisedDate',
        numeric: true,
        disablePadding: false,
        label: 'Promised Date', //same as  date
    },
    {
        id: 'wholesale_price',
        numeric: true,
        disablePadding: false,
        label: 'Unit Cost',
    },
    {
        id: 'retail_price',
        numeric: true,
        disablePadding: false,
        label: 'Selling Price Unit',
    },
    {
        id: 'discount',
        numeric: true,
        disablePadding: false,
        label: 'Discount',
    },
    {
        id: 'promotion', //dummy data
        numeric: true,
        disablePadding: false,
        label: 'Promotion',
    },
    {
        id: 'inventory_cost_distribution', //dummy data
        numeric: true,
        disablePadding: false,
        label: 'Storage Cost',
    },
    {
        id: 'inventory_carry_cost_distribution',
        numeric: true,
        disablePadding: false,
        label: 'Inventory Caring Cost',
    }
];


const ViewScenarioAnalyzerWithApi = () => {
    const analyzer = new ScenarioAnalyzer();
    // Example usage
    const numbers = [0, 5, 10, 15, 18, 25, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90, 100, 150, 190];
    const numParts = 5;
    let result = splitAndCount(numbers, numParts);

    const [typeOfData, setChartType] = React.useState('');
    const [selectedWeek, setWeek] = React.useState('');
    const [weekOptions, setWeekOptions] = useState([]);
    const [fetchedData, setFetchedData] = useState({});
    const [histChartData, setHistChartData] = useState([]);
    const [showMean, toggleMean] = useState(false)
    const [selectedMaterial, setMaterial] = useState(null)
    const [inventoryList, setMaterialList] = useState(-1);
    const [originalFetchedData, setOriginalFerchedData] = useState({})
    const [isEditclicked, setIsEditClicked] = useState("");


    const getInventoryList = (materialId, inventList, orderid) => {
        setIsLoading(true);
        let replacementObj = {
            "materialIdParam": materialId,
            "inventoryListParam": inventList,
            "orderneedstobeplaced": orderid
        };
        let url = replaceMultipleStringValues(replacementObj, MATERIALANDINVENTORYLIST);
        axios.post(url, {})
            .then(response => response.data)
            .then(data => {
                if (data?.body) {
                    console.log(JSON.parse(data.body))
                    setFetchedData(JSON.parse(data.body))
                    setOriginalFerchedData(JSON.parse(data.body))
                    setIsLoading(false)
                }
            })
    }

    const getChartData = (histDataToProcess) => {
        // console.log(histDataToProcess, "========")
        return histDataToProcess?.map(data => {
            return {
                "group": 'Dataset 1',
                'count': data
            }
        })
    }

    const getDynamicMeanData = (meanDataToCalculate) => {
        let weekList = Object.keys(meanDataToCalculate)
        if (weekList && weekList.length > 0) {
            let meanChartData = [];
            weekList.forEach(weekData => {
                let dataToCalculate = meanDataToCalculate[weekData];
                if (!dataToCalculate || dataToCalculate.length === 0) {
                    return null; // Return null for an empty array or handle it according to your application's logic
                } else {
                    var total = dataToCalculate.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    var mean = total / dataToCalculate.length;
                    meanChartData.push({
                        group: 'Dataset 1',
                        key: 'Week - ' + weekData,
                        value: mean
                    })

                }
            })

            return meanChartData
        }
    }

    const renderAllCharts = () => {
        let dataToRender = []
        if (originalFetchedData?.chart_data && Object.keys(originalFetchedData.chart_data).length > 0) {
            Object.keys(originalFetchedData.chart_data).map(data => {
                dataToRender.push(
                    <div style={{ background: "#F5F5F5", padding: "1%", height: "100%", marginTop: "1%", width: "100%", overflowX: "auto" }}>
                        <div style={{ textTransform: "capitalize", fontWeight: "bold" }}>{data.replace("_", " ")}</div>
                        <div style={{ display: "flex", flexBasis: "auto", flexDirection: "row", padding: "1% 0% 1% 0%", gap: "1%" }}>
                            {
                                Object.keys(originalFetchedData.chart_data[data]).map((weeks, index) => {
                                    let optionsForHist = { ...histChartOptions }
                                    optionsForHist['title'] = "Week - " + weeks
                                    if (Object.keys(originalFetchedData.chart_data[data]).length == index + 1) {
                                        // console.log(getChartData(originalFetchedData.chart_data[data][weeks]),"-------")
                                        return <>
                                            <HistogramChart
                                                data={getChartData(originalFetchedData.chart_data[data][weeks])}
                                                options={optionsForHist}
                                            />
                                            <LineChart
                                                data={getDynamicMeanData(originalFetchedData.chart_data[data])}
                                                options={lineChartOptions}
                                            ></ LineChart>
                                        </>
                                    } else {
                                        return <HistogramChart
                                            data={getChartData(originalFetchedData.chart_data[data][weeks])}
                                            options={optionsForHist}
                                        />
                                    }
                                }

                                )
                            }
                        </div>
                    </div>
                )
            })
        }
        return dataToRender
    }

    const handleMaterialSelect = (value) => {
        setMaterial(value)
        getInventoryList(value, -1, -1)
        // getInventoryList(value);
    }

    const handleChartSelect = (value) => {
        setChartType(value);
        let weekList = Object.keys(fetchedData[value])
        if (weekList && weekList?.length > 0)
            setWeekOptions(weekList)
        setWeek(undefined)
        setHistChartData([])
    };

    const handleWeekSelection = (value) => {
        setWeek(value)
        let selectedChartData = fetchedData[typeOfData][value]?.map(data => {
            return {
                "group": 'Dataset 1',
                'count': data
            }
        })
        setHistChartData(selectedChartData)
    }

    const getMeanData = () => {
        let weekList = Object.keys(fetchedData[typeOfData])
        if (weekList && weekList.length > 0) {
            let meanChartData = [];
            weekList.forEach(weekData => {
                let dataToCalculate = fetchedData[typeOfData][weekData];
                if (!dataToCalculate || dataToCalculate.length === 0) {
                    return null; // Return null for an empty array or handle it according to your application's logic
                } else {
                    var total = dataToCalculate.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    var mean = total / dataToCalculate.length;
                    meanChartData.push({
                        group: 'Dataset 1',
                        key: 'Week - ' + weekData,
                        value: mean
                    })

                }
            })

            return meanChartData
        }
    }



    const barChartData = {
        labels: result.parts,
        barthickness: 'flex',
        barPercentage: 1,
        categoryPercentage: 1,
        datasets: [{
            label: 'My First Dataset',
            data: result.counts,
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)'
            ],
            borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)'
            ],
            borderWidth: 1,

            lineTension: 0,
            fill: false,
            borderJoinStyle: "round",
            borderWidth: 0.2,
            barPercentage: 1,
            categoryPercentage: 1,
            barThickness: "flex"

        }]
    };


    const theme = useTheme();
    const [seeds, setSeeds] = useState([1627, 2599, 2127, 2242, 2326]);

    const [data, setData] = useState([]);
    const [modelOpen, setModelOpen] = useState(false);
    const [chartModel, setChartModel] = useState([]);
    const [optionModel, setOptionModel] = useState();
    /////////////////////////////

    const [chartDatalist, setChartDatalist] = useState([]);
    const [chartFilterDatalist, setChartFilterDatalist] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [histogramData, setHistogramData] = useState([]);
    const [open, setIsOpen] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState([]);
    const chartRef = useRef([]);

    var display_dict = [
        { id: 0, value: "Value_Function", tooltip: "your_tooltip_here", title: "Value Function" },
        { id: 1, value: "begin_inventory", tooltip: "your_tooltip_here", title: "begin inventory" },
        { id: 2, value: "end_inventory", tooltip: "your_tooltip_here", title: "end inventory" },
        { id: 3, value: "demand", tooltip: "your_tooltip_here", title: "demand" },
        { id: 4, value: "gross_margin", tooltip: "your_tooltip_here", title: "gross margin" },
        { id: 5, value: "units_sold", tooltip: "your_tooltip_here", title: "units sold" },
        { id: 6, value: "inventory_cost", tooltip: "your_tooltip_here", title: "inventory cost" },
        { id: 7, value: "inventory_carry_cost", tooltip: "your_tooltip_here", title: "inventory carry cost" },
        { id: 8, value: "lost_margin", tooltip: "your_tooltip_here", title: "lost margin" },
        { id: 9, value: "lost_sales", tooltip: "your_tooltip_here", title: "lost sales" },
        { id: 10, value: "stockout", tooltip: "your_tooltip_here", title: "stockout" },
    ];

    chartRef.current = Array(11)
        .fill(null)
        .map(() => {
            return document.createElement("canvas");
        });
    const density_config = require("./scenario_analyzer_density.json");
    const config_data = density_config["orders"]["data"][0];
    config_data["demand"] = density_config["demand"]["data"][0]["demand"];
    const density_config_sliced = density_config["demand_density_forecast"][
        "data"
    ].slice(0, 4000);
    var demandArray = new Array(4);
    for (var i = 0; i < demandArray.length; i++) {
        demandArray[i] = new Array();
        for (let j = i; j < 4000; j = j + 4) {
            demandArray[i].push(density_config_sliced[j]["yhat"]);
        }
    }
    const fieldsArray = density_config["orders"]["data"];
    //
    const handleToggle = () => {
        setIsOpen((prev) => !prev);
    };
    const generateChart = () => {
        var newHistogramData = [];
        for (let i = 0; i < chartRef.current.length; i++) {
            const currentRef = chartRef.current[i];
            if (currentRef) {
                newHistogramData = analyzer.generateHistogramData(
                    config_data,
                    seeds,
                    display_dict[i].value,
                    demandArray,
                    fieldsArray
                );
                setHistogramData(newHistogramData);
                let date = analyzer?.dates?.map((num) => Math.floor(num));
                const chartData = {
                    title: display_dict[i].title,
                    value: display_dict[i].value,
                    tooltip: display_dict[i].tooltip,
                    chartData: {
                        labels: date,
                        datasets: newHistogramData.returnData,
                    },
                };
                setChartDatalist((chartDatalist) => {
                    return [...chartDatalist, chartData];
                });
            }
        }
        setIsLoading(false);
    };

    // useEffect(() => {
    //     generateChart();
    // }, []);
    const handleSeedChange = (index, value) => {
        const newSeeds = [...seeds];
        newSeeds[index] = parseInt(value);
        setSeeds(newSeeds);
    };

    const handleUpdateClick = () => {
        setChartDatalist([]); //reset
        generateChart();
    };
    const handleModel = (data) => {
        setModelOpen(true);
        setChartModel(data);
    };
    const handleExport = () => {
        let data = histogramData?.newConfigData;
        const jsonData = JSON.stringify(data);
        const blob = new Blob([jsonData], {
            type: "application/json;charset=utf-8",
        });

        saveAs(blob, "HistogramData.json");
    };
    const handleSelectChange = (values) => {
        setSelectedTitle(values);
    };
    const handleReset = () => {
        setSelectedTitle();
        setChartDatalist([]);
        generateChart();
    };


    const handleSave = (e) => {
        let dataToSave = fetchedData.material_params_data;
        let dataListToSave = [dataToSave.beginning_inventory_original, ...dataToSave.pre_orders_yet_to_arrive_in_quantities];
        // console.log(dataListToSave, "--------")
        // getInventoryList(selectedMaterial,dataListToSave).then()
        // setIsEditClicked("")
    }

    const handleCancel = (e) => {
        setFetchedData(originalFetchedData);
    }

    const renderEditableData = () => {
        let dataToRender = [];
        let arrayDataToRender = [];

        const handleChange = (e) => {
            const { name, value, id } = e.target;
            if (originalFetchedData.material_params_data[e.target.name].constructor === Array) {
                // console.log("test inside array condition")
                let dataToChange = fetchedData.material_params_data[e.target.name];
                // console.log(dataToChange[parseInt(e.target.id)], "-------", e.target.id)
                setFetchedData(prevData => {
                    const updatedMaterialParamsData = {
                        ...prevData.material_params_data,
                        [e.target.name]: prevData.material_params_data[e.target.name].map((item, index) => {
                            if (index === parseInt(e.target.id)) {
                                return parseInt(e.target.value);
                            } else {
                                return item;
                            }
                        })
                    };

                    return {
                        ...prevData,
                        material_params_data: updatedMaterialParamsData
                    };
                });
            }
            else {
                setFetchedData(prevData => ({
                    ...prevData,
                    material_params_data: {
                        ...prevData.material_params_data,
                        [name]: parseInt(value) // Assuming you want to parse the value to an integer
                    }
                }));
            }
        };

        if (originalFetchedData?.material_params_data && Object.keys(originalFetchedData.material_params_data).length > 0) {
            Object.keys(originalFetchedData.material_params_data).map((data, index) => {
                if (typeof (originalFetchedData.material_params_data[data]) == 'number')
                    dataToRender.push(
                        <span>
                            <span style={{ fontWeight: "bold", margin: dataToRender.length == 0 ? "0% 1% 0% 0%" : "0% 1% 0% 1%", textTransform: "capitalize" }}>
                                {data.replace(/_/g, " ") + " - "}
                            </span>
                            <span>
                                <EditableComponent dataToEdit={fetchedData.material_params_data[data]} id={data} name={data} onChange={handleChange} isEditAllowed={editableAllowedScenarioAnalyzer.includes(data)} inputStyle={{ width: "10%" }} onSave={handleSave} onCancel={handleCancel} setIsEditClicked={setIsEditClicked} isEditclicked={isEditclicked} />
                            </span>
                        </span>
                    )
                if (originalFetchedData.material_params_data[data].constructor == Array)
                    arrayDataToRender.push(
                        <div>
                            <span style={{ fontWeight: "bold", margin: "0% 1% 0% 0%", textTransform: "capitalize" }}>{data.replace(/_/g, " ") + " - "}</span>
                            {
                                <EditableComponent dataToEdit={fetchedData.material_params_data[data]} id={data} name={data} onChange={handleChange} isEditAllowed={editableAllowedScenarioAnalyzer.includes(data)} inputStyle={{ width: "10%" }} onSave={handleSave} onCancel={handleCancel} setIsEditClicked={setIsEditClicked} isEditclicked={isEditclicked} />
                            }
                            {/* {
                                originalFetchedData.material_params_data[data].map(data =>
                                    <span>{data + ", "}</span>
                                )
                            } */}
                        </div>
                    )
            })
            return <div style={{ marginTop: "1%" }}>
                <div style={{ marginTop: "1%" }}>{dataToRender}</div>
                <div style={{ marginTop: "1%" }}>{arrayDataToRender}</div>
            </div>

        }
    }

    const filteredChartDataList = chartDatalist.filter(
        (chartData, index) =>
            selectedTitle.length === 0 || selectedTitle.includes(display_dict[index].value)
    );

    const getLineChartData = (arr, weekNumber) => {
        const countMap = {};

        // Count occurrences of each value
        arr.forEach((value) => {
            countMap[value] = (countMap[value] || 0) + 1;
        });

        // Extract unique values
        const uniqueValues = Object.keys(countMap);

        // Map unique values to count for line chart
        // const uniqueValueCounts = uniqueValues.map((value) => ({
        //     group: "Week-" + weekNumber,
        //     key: value,
        //     value: countMap[value],
        // }));

        //for histogram chart
        const uniqueValueCounts = arr.map((value) => ({
            group: "Week-" + weekNumber,
            count: value,
        }));

        return uniqueValueCounts;
    }

    const renderScenarioAnalyzerTable = () => {
        // console.log(originalFetchedData, '-----------------')
        let obj = {};
        let tableData = [];
        let lineChartData = [];
        let obj1 = {};
        if (originalFetchedData && Object.keys(originalFetchedData).length > 0)
            Object.keys(originalFetchedData?.chart_data)?.forEach((data, index) => {
                Object.keys(originalFetchedData?.chart_data[data])?.forEach((weeklyData, weekIndex) => {
                    let dataToCalculate = originalFetchedData?.chart_data[data][weeklyData];
                    var total = dataToCalculate.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    var mean = total / dataToCalculate.length;
                    if (!obj[data])
                        obj[data] = [];
                    obj[data].push(mean)

                    const sortedArr = dataToCalculate.sort((a, b) => a - b);
                    const totalElements = sortedArr.length;
                    const index5th = Math.ceil(0.05 * totalElements);
                    const index95th = Math.ceil(0.95 * totalElements);

                    const percentile5th = sortedArr[index5th - 1];
                    const percentile95th = sortedArr[index95th - 1];

                    let arrData = originalFetchedData?.chart_data[data][weeklyData];
                    // console.log(getLineChartData(arrData,weeklyData));
                    if (weekIndex > 0) {
                        if (!obj1[data]) {
                            obj1[data] = [...getLineChartData(arrData, weeklyData)]
                        } else {
                            let newObj = [...obj1[data]];
                            obj1[data] = [...newObj, ...getLineChartData(arrData, weeklyData)]
                        }
                    }
                    if (!tableData[weekIndex]) {
                        tableData[weekIndex] = {
                            id: weekIndex + 1,
                            [data]: mean
                        }
                        if (data == "demand_distribution") {
                            let obj = {
                                'forecastedMinDemand': percentile5th.toString(),
                                'forecastedMaxDemand': percentile95th,
                                'discount':originalFetchedData?.material_params_data?.discount,
                                'retail_price':originalFetchedData?.material_params_data?.retail_price,
                                'wholesale_price':originalFetchedData?.material_params_data?.wholesale_price,
                                'pre_orders_yet_to_arrive_in_quantities':originalFetchedData?.material_params_data?.pre_orders_yet_to_arrive_in_quantities[index],
                                'promotion':1
                            }
                            tableData[weekIndex] = {
                                ...tableData[weekIndex],
                                ...obj
                            }
                        }
                    }
                    else {
                        let newTableData = {
                            [data]: mean,
                            ...tableData[weekIndex]
                        }
                        tableData[weekIndex] = { ...newTableData };
                        if (data == "demand_distribution") {
                            let obj = {
                                'forecastedMinDemand': percentile5th.toString(),
                                'forecastedMaxDemand': percentile95th,
                                'discount':originalFetchedData?.material_params_data?.discount,
                                'retail_price':originalFetchedData?.material_params_data?.retail_price,
                                'wholesale_price':originalFetchedData?.material_params_data?.wholesale_price,
                                'pre_orders_yet_to_arrive_in_quantities':originalFetchedData?.material_params_data?.pre_orders_yet_to_arrive_in_quantities[index],
                                'promotion':123
                            }
                            tableData[weekIndex] = {
                                ...tableData[weekIndex],
                                ...obj
                            }
                        }
                    }
                })
            })
        console.log(obj, "========", tableData, "=============", obj1, originalFetchedData);
        return (
            <Grid container xs={12} spacing={2} sx={{padding:"1%"}}>
                <Grid item xs={12}>
                    {tableData?.length>0 &&
                    <ScenarioAnalyserTable rows={tableData} headCells={scenarioAnalyzerTableHeader} dense={true} />}
                    {
                        !selectedMaterial && 
                        <div>Choose material for forecast</div>
                    }
                </Grid>
                {obj1 && Object.keys(obj1).length > 0 && Object.keys(obj1).map(data =>
                    <Grid container xs={12} spacing={2} sx={{marginTop:"1%"}}>
                        <Grid item xs={12}>
                            <div style={{ textTransform: "capitalize", fontWeight: "bold" }}>{data.replace("_", " ")}</div>
                        </Grid>
                        <Grid item xs={6}>
                            {/* <LineChart
                                data={obj1[data]}
                                options={saLineChartOptions}
                            ></ LineChart> */}
                            <HistogramChart
                                data={obj1[data]}
                                options={saHistChartOptions}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LineChart
                                data={getDynamicMeanData(originalFetchedData?.chart_data[data])}
                                options={lineChartOptions}
                            ></ LineChart>
                        </Grid>
                    </Grid>
                )
                }
            </Grid>
        )
    }

    const rawData = Array(100).fill().map(Math.random);
    return (
        <div className="ViewScenarioAnalyzer">
            <div className="AnalyserChart">
                <div>
                    <Select
                        placeholder="Select Material Type"
                        className="chartSelector"
                        options={materialListDetails?.map(types => {
                            return {
                                value: types.material_id,
                                label: types.material_id
                            }
                        })}
                        onChange={(value) => { handleMaterialSelect(value) }}
                    />
                </div>
                <div>
                    <div style={{ background: "#F5F5F5", width: "100%", padding: "1%", height: "100%", marginTop: "1%", overflow: "auto" }}>
                        {
                            isLoading && (
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", margin: "auto" }}>
                                    <CircularProgress />
                                </div>
                            )
                        }
                        {
                            renderScenarioAnalyzerTable()
                        }
                        {/* {
                            renderEditableData()
                        } */}
                    </div>
                    <div>
                        {
                            // renderAllCharts()
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewScenarioAnalyzerWithApi;
