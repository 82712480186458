import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import {
  CURRENT_ENVIRONMENT,
  ClientId,
  DomainId,
} from "../../constants/envConstants";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN_ID
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = "";

  const history = createBrowserHistory();

  const onRedirectCallback = (appState) => {
    
    if (appState?.returnTo === "/") {
      history.push("/");
    } else {
      history.push(appState?.returnTo || "/");
    }
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      //   audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
