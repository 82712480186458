import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import React from "react";


export const LoadingBar = () => {
  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );
};

export const DisabledIcon=()=>{
  return(
    <PendingIcon fontSize="medium" color="disabled"/>
  )
}
export const CheckTick =()=>{
  return (
    <CheckCircleIcon fontSize="medium" color="primary"/>
  )
}
export const ShowErrorIcon =()=>{
  return (
    <ErrorIcon fontSize="medium" color="error"/>
  )
}
export const CircularIndeterminate=()=> {
  return ( <div >
    <CircularProgress size={20}/>
  </div>)
   
     
}


