import { combineReducers } from "redux";
import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {UploadFileReducer} from "./uploadFile/reducer"
import {ModalReducer} from "./Modals/reducer";
import { ModalUploadReducer } from "../aws/ModelSelectionUpload/modalUploadReducer";
import { SampleDataReducer } from "./Sample/reducer";
const rootPersistConfig = {
  key: "root",
  storage,
};

const reducers = persistCombineReducers(rootPersistConfig, {
  UploadFileReducer,
  ModalReducer,
  ModalUploadReducer,
  SampleDataReducer

});

export default reducers;
