import AWS from "aws-sdk";
import { useDispatch, useSelector } from "react-redux";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRECT_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
  region: "us-west-2",
  signatureVersion: "v4",
  httpOptions: {
    connectTimeout: 5 * 10000, // time succeed in starting the call
    timeout: 0, // time to wait for a response
    // the aws-sdk defaults to automatically retrying
    // if one of these limits are met.
  },
});

export const uploadFileSuccess = () => ({
  type: "UPLOAD_SUCCESS",
});

export const uploadFileFailure = () => ({
  type: "UPLOAD_FAILURE",
});

export const setUploadFile = (file) => ({
  type: "SELECT_FILE",
  payload: file,
});

export const uploadProgress = (percentage) => ({
  type: "UPLOAD_PROGRESS",
  payload: percentage,
});
export const resetUploadState = () => ({
  type: "RESET_STATE",
});

export const uploadFile = () => async (dispatch, getState) => {
  const selectedFile = getState().UploadFileReducer.selectedFile;
  console.log("Selected file:", selectedFile);

  if (!selectedFile) {
    console.log("No file selected.");
    return;
  }
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: selectedFile.name,
    Body: selectedFile,
  };
  try {
    const managedUpload = myBucket.putObject(params);

    managedUpload.on("httpUploadProgress", (progress) => {
      const { loaded, total } = progress;
      const percentage = Math.round((loaded / total) * 100);
      dispatch(uploadProgress(percentage));
    });

    console.log("Starting upload...");
    await managedUpload.promise();
    console.log("Upload completed successfully.");

    dispatch(uploadFileSuccess());
  } catch (error) {
    dispatch(uploadFileFailure());
    console.error("Upload error:", error.message);
  }
};
