import React from 'react'
import { listWorkspaceStyles } from '../dashboard/SampleModal/ModalListStyles';
import { Box, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

const FileUploadLayout = () => {
    const classes = listWorkspaceStyles();

  return (
    <div className={classes.layout}>
      <Typography className={classes.title}>Data Upload</Typography>
      <Typography className={classes.subheading}>Efficiently upload and analyze time series data with our streamlined data upload feature, providing instant insights into uncertainties for swift decision-making.</Typography>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Outlet />
      </Box>
    </div>
  )
}

export default FileUploadLayout;